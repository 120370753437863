import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';

interface Props {
    breadcrumbs: string;
}

const Map = () => {
  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWFyaWRhdGEiLCJhIjoiY2s1Zml3MGFzMmJ5bzNrbXU0MWI3bno4MCJ9.N5idq0WLbrkeGb0EDSJfug';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: 1
    });

    return () => map.remove();
  }, []);

  return <div id="map"></div>;
};

const Interactive_Map: React.FC<Props> = (breadcrumbs) => {
  return (
  <div id='interactive_map_container'>
      <Map/>
    </div>
  );
};

export default Interactive_Map;
