import React, { useEffect, useState, useRef, useMemo, useCallback, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { post_data } from '../../Javascript/API_Calls.js';
import { sendDataToParent, got_tracked_vessel_uuid, host_url } from '../../Javascript/ModuleFunctions/Iframe_Handler.js';
import { handleSASSNotificationsList, handleSASSRecordDetails } from '../../Javascript/ModuleFunctions/Notifications.js';
import * as SP from '../../Javascript/ComponentData/SSAS_Page.js';
import * as SF from '../../Javascript/ModuleFunctions/SSAS_Functions.js';
import { all_user_settings, convertToTimeZone } from '../../Javascript/Other/General.js';
import 'bootstrap/dist/css/bootstrap.min.css';
//import '../../CSS/Module_CS/details.css';
import '../../CSS/Module_CS/components.css';
import { Container, Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';
import CustomNotification from '../../Components/CustomNotification'
import CustomTable from '../../Components/CustomTable';

interface Props {
  breadcrumbs: string;
}
const SSASDetails: React.FC<Props> = ({ breadcrumbs}) => {
    const [vesselUuid, setVesselUuid] = useState<string>("");
    const [activeKey, setActiveKey] = useState<string | null>(null); // TypeScript-safe useState
    const [vesselSSASActiveState, setVesselSSASActiveState] = useState<string>("");
    const [triggerReRender, setTriggerReRender] = useState(false);
    const [is_loading, set_is_loading] = useState(true);
    const [SSASVesselData, setSSASVesselData] = useState<SP.SSASVessel | null>(null);
    const rerender = useReducer(() => ({}), {})[1]

    const copyVesselSSASEmail = (emailAddress: string) =>{
        navigator.clipboard.writeText(emailAddress);
    };
    const checkboxContacts = (name:string, value:boolean) => {
        console.log('I '+name+'!', value);
    };
    const deleteContact = (row_id:number) => {
        alert('Delete contact: ' + row_id);
    };
    const toggleExpandContact = (row_id: string) => {
    };

    useEffect(() => {
       const getTrackedID = async () => {
           const newTrackedUUID = got_tracked_vessel_uuid();

           if (newTrackedUUID !== "" && vesselUuid === "") {
               setVesselUuid(newTrackedUUID);
           }
           if (newTrackedUUID !== "" || vesselUuid) {
               clearInterval(interval);
           }
           attemptCount += 1;
           if (attemptCount >= 30) {
               console.warn("Max attempts reached, stopping interval.");
               set_is_loading(false);
               clearInterval(interval);
           }
       };

       let interval: NodeJS.Timeout;
       let attemptCount = 0;
       if(!host_url.href.includes("localhost:3000")){
           sendDataToParent({ item:"host_url" });
           if (vesselUuid === "") {
               interval = setInterval(getTrackedID, 2000);
           }
       }
       else{
           setVesselUuid("58c33e24-1366-4dbd-a3fc-ef89a90fa8d4");
           set_is_loading(false);
       }
    }, [])

    const toggleTab = (tab: string) => {
        setActiveKey(activeKey === tab ? null : tab); // Toggle tab open/close
    };

    const handleSSASActiveButton = async (action: string, uuid:string ) => {
        let valid_email = SF.validateVesselEmailAddress();
        if(valid_email[0]){
            if(uuid === '' && vesselSSASActiveState == "0"){
                const result = await SP.ActivateSSASTracking('58c33e24-1366-4dbd-a3fc-ef89a90fa8d4', valid_email[1]);
                if(result) window.location.reload();
            }
            else{
                const result = await SP.ActivateSSASTracking(uuid, valid_email[1]);
                if(result) window.location.reload();
            }
        }
    };

    const SSASActiveState = useMemo(() => {
        return SF.getVesselDetailsButton(vesselSSASActiveState, handleSSASActiveButton, vesselUuid);
    }, [vesselSSASActiveState, vesselUuid]);

    const [affiliationList, setAffiliationList] = useState<SP.SSASAffiliation[] | null>(null);

    const [userContact, setUserContact] = useState<SP.SSASContact | null>(null);
    const [contacts, setContacts] = useState<SP.SSASContact[] | null>(null);
    const [allContacts, setAllContacts] = useState<SP.SSASContact[] | null>(null);
    const [contactsCascade, setContactsCascade] = useState<SP.SSASContact[] | null>(null);
    const [contactsCascadeColumns, setContactsCascadeColumns] = useState<ColumnDef<SP.SSASContact>[]>(SF.getContactsCascadeTableData({}, { checkboxContacts, deleteContact }, "") as ColumnDef<SP.SSASContact>[]);
    const [contactsColumns, setContactsColumns] = useState<ColumnDef<SP.SSASContact>[]>(SF.getVesselTabContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }, "", SSASVesselData) as ColumnDef<SP.SSASContact>[]);

    const [allArchivedAlerts, setAllArchivedAlerts] = useState<SP.SSASVessel[] | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            if (contacts != null) {
                let temp_contacts: SP.SSASContact[] = [];
                contacts.forEach((item) => {
                    if (item.priority_level > 0) {
                        temp_contacts.push(item);
                    }
                });
                if (temp_contacts.length > 0) {
                    temp_contacts.sort((a, b) => a.priority_level - b.priority_level);

                    temp_contacts = await SF.combineContactAffiliation(temp_contacts, affiliationList);
                    setContactsCascade(temp_contacts);
                }
            }

        }
        fetchData();
    }, [contacts]);

    useEffect(() => {
        const fetchData = async () => {
            if(affiliationList != null){
                let contact_ids:string[] = [...new Set(affiliationList.map(x => x.contact_uuid))];

                let resContact:SP.SSASContact[] = [];
                if(contact_ids.length > 0){
                    resContact = await SP.getContactData(contact_ids);
                    resContact = await SF.combineContactAffiliation(resContact, affiliationList);
                }

                if(allContacts != null){
                    let addMastered = [];
                    if(contact_ids.length > 0){
                        let resContactUuids = resContact.map(x => x.contact_uuid);
                        addMastered = allContacts.filter(x => (x.active_email || x.active_screen || x.test_email || x.test_screen) && !resContactUuids.includes(x.contact_uuid));
                    }
                    else{
                        addMastered = allContacts.filter(x => (x.active_email || x.active_screen || x.test_email || x.test_screen));
                    }

                    if(addMastered != null){
                        resContact = [...resContact, ...addMastered]
                    }
                }
                setContacts(resContact);
                if(allContacts != null){
                    let resAllContact = await SF.combineContactAffiliation(allContacts, affiliationList);
                    setAllContacts(resAllContact);
                }
            }
        }
        fetchData();
    }, [affiliationList])

    const toggleExpandTestSchedule = (row_id: string) => {
      if(testSchedule != null){
          setTestSchedule(prevData =>
            (prevData ?? []).map(test =>
              test.schedule_uuid === row_id ? { ...test, expand: !test.expand } : test
            )
          );
      }
    };

    const [testSchedule, setTestSchedule] = useState<SP.SSASScheduledTests[] | null>(null);
    const [testScheduleColumns, setTestScheduleColumns] = useState<ColumnDef<SP.SSASScheduledTests>[]>(SF.getScheduleTableData({}, false, { toggleExpandTestSchedule }, "") as ColumnDef<SP.SSASScheduledTests>[]);

    useEffect(() => {
        setTestScheduleColumns(SF.getScheduleTableData({}, false, {toggleExpandTestSchedule}, "") as ColumnDef<SP.SSASScheduledTests>[]);
    },[testSchedule]);
    const expandElementTestSchedule = useCallback((row_id: string, more_info: any) => {
      if(more_info.expand){
          return SF.expandScheduledTestContent(more_info);
        }
        else {
            return (null);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if(SSASVesselData === null){
                const res = await SP.getSSASVesselData([], [vesselUuid], "", );
                setSSASVesselData(res[0]);
            }
            if(userContact == null){
                const res = await SP.getContactData([],[], true);
                if(res.length > 0){
                    setUserContact(res[0]);
                }
            }
            if(allContacts == null){
                const res = await SP.getContactData([],[]);
                setAllContacts(res);
            }
        }
        if(vesselUuid != ""){
            fetchData();
            set_is_loading(false);
        }
    }, [vesselUuid])
    useEffect(()=>{
        const fetchData = async () => {
            if(SSASVesselData != null){
                const res_test = await SP.getSSASScheduledTests([], [SSASVesselData.ssas_vessels_uuid], "", true, false, false);
                setTestSchedule(res_test);
                res_test.map(i => i.vessel_info = SSASVesselData);

                const res = await SP.getAffiliationData([],[],[SSASVesselData.ssas_vessels_uuid]);
                setAffiliationList(res);
                if(SSASVesselData != null){
                    setVesselSSASActiveState(SSASVesselData.current_status);
                }

                setContactsColumns(SF.getVesselTabContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }, "", SSASVesselData) as ColumnDef<SP.SSASContact>[]);
            }

        }
        fetchData();
    }, [SSASVesselData]);
    const contactDropdown = useCallback((is_cascade:boolean) => {
        if(allContacts != null && SSASVesselData != null ){
            let remove_cont_ids: string[] = [];
            if(is_cascade){
                if(contactsCascade != null){
                    remove_cont_ids = contactsCascade.map(item => item.contact_uuid);
                }
            }
            else{
                if(contacts != null){
                    remove_cont_ids = contacts.map(item => item.contact_uuid);
                }
            }
            const updatedContacts = allContacts.filter(contact => !remove_cont_ids.includes(contact.contact_uuid));
            let keyPart = "contact-";
            if(is_cascade) keyPart = "cascade-"
            return (
                <Form.Select className="userContactSelect"  aria-label="Select a user" onChange={e => {
                       const selectedUser = allContacts.find(user => user.contact_uuid === e.target.value);
                       if(selectedUser && is_cascade) selectedUser.priority_level = 1
                       if (selectedUser) { SF.addContactAssociation(selectedUser, SSASVesselData); }
                   }}>
                  <option> Add a contact</option>
                  {updatedContacts.map(user => (
                    <option key={keyPart + user.contact_uuid} value={user.contact_uuid} >{user.user_name}</option>
                  ))}
                </Form.Select>
            );
        }
    }, [allContacts, SSASVesselData, contacts, contactsCascade]);

    return (
    <>
        {is_loading ? (
            <div className="loading_gif">
                <p>Loading...</p>
            </div>
        ) : (
          <div id="details_SSAS_tab" >
              <div id="scheduleTestPopup"></div>
              <Container className="initial_content" fluid>
                <Row>
                  <Col>
                    <p>STATUS: {SF.getAlertStatusElement(SSASVesselData?.current_status ? SSASVesselData.current_status : 'NOT ENABLED')}</p>
                  </Col>
                  <Col>
                    { SSASVesselData?.vessel_email ? (<Button className='email_update' onClick={(e) => SF.updateVesselEmail(SSASVesselData)}>Update</Button>) : "" }
                    <Form.Control id="vessel_email_for_ssas" placeholder="Please provide vessel email address" value={ SSASVesselData?.vessel_email ? SSASVesselData.vessel_email : ""}/>
                    <p id="vessel_email_error_notice" className="error_notice">Vessel email is required for validation</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                      <div className="text_cont">
                        <p>Email Address: </p>
                        <Button className="copy" onClick={() => copyVesselSSASEmail(SSASVesselData?.email_address ? SSASVesselData.email_address : "")} variant="light"><FontAwesomeIcon icon={['far', 'copy']} /></Button>
                        <p>{SSASVesselData?.email_address ? SSASVesselData.email_address : ""}</p>
                      </div>
                  </Col>
                  <Col>
                      {SSASActiveState}
                  </Col>
                </Row>
                <Row>
                    <div style={{ float: 'right' }} className="dummy_buttons">
                        <Button onClick={() => handleSASSNotificationsList([
                                        ['TEST', '1', 'TEST ALERT VESSEL', new Date()],
                                        ['ALERT', '2', 'MAIN ALERT VESSEL', new Date()]
                                    ])} variant="light">test new notif</Button>
                        <Button onClick={() => handleSASSRecordDetails('ALERT', '1', 'alert_vessel' )} variant="light">test alert</Button>
                        <Button onClick={() => handleSASSRecordDetails('TEST', '2', 'test_vessel' )} variant="light">test test</Button>
                    </div>
                </Row>
              </Container>
            <Accordion activeKey={activeKey}>
              <Card>
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={() => toggleTab('0')}>Event Log</Accordion.Header>
                  <Accordion.Body>
                    Dashboard content goes here.
                  </Accordion.Body>
                </Accordion.Item>
              </Card>

              <Card>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={() => toggleTab('1')}>Call Cascade</Accordion.Header>
                  <Accordion.Body className="cascade_cont">
                    <CustomTable breadcrumbs={breadcrumbs+"|SSAS_TAB|CONTACTCASCADE"} columns={contactsCascadeColumns} data={contactsCascade}
                    rows_displayed={contactsCascade ? contactsCascade.length : 0} dropdown_placeholder={"All"} expand_element={null}/>
                    {contactDropdown(true)}
                  </Accordion.Body>
                </Accordion.Item>
              </Card>

              <Card>
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => toggleTab('2')}>Alert Recipients</Accordion.Header>
                  <Accordion.Body className="contacts_cont">
                    <CustomTable breadcrumbs={breadcrumbs+"|SSAS_TAB|CONTACT"} columns={contactsColumns} data={contacts}
                    rows_displayed={contacts ? contacts.length : 0} dropdown_placeholder={"All"} expand_element={null}/>
                    {contactDropdown(false)}
                  </Accordion.Body>
                </Accordion.Item>
              </Card>

              <Card>
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={() => toggleTab('3')}>Test Schedules</Accordion.Header>
                  <Accordion.Body className="schedule_cont">
                    <CustomTable breadcrumbs={breadcrumbs+"|SSAS_TAB|TEST_SCHEDULE"} columns={testScheduleColumns} data={testSchedule}
                    rows_displayed={testSchedule ? testSchedule.length : 0} dropdown_placeholder={"All"} expand_element={expandElementTestSchedule}/>

                    <Button variant="primary" id="schedule_test_btn" onClick={() => SP.scheduleNewTest(SSASVesselData, userContact, "scheduleTestPopup")}>Schedule New Test</Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            </Accordion>
          </div>
          )}
      </>
    );
};

export default SSASDetails;