import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { showLoadingPanel, hideLoadingPanel} from '../Javascript/Other/General.js';
import { session_id } from '../Javascript/session.js';
import { post_data, get_page_content } from '../Javascript/API_Calls.js';
import * as detail_js from '../Javascript/ModuleFunctions/Details_Page.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/Module_CS/details.css';
import MobileDetails from './MobileDetails';
import VesselDetails from './VesselDetails';

interface Props {
  breadcrumbs: string;
  detailsContentType: String;
  contentId: String;
}
const DetailsPage: React.FC<Props> = ({ breadcrumbs, detailsContentType, contentId }) => {
    const [mobile_content, set_mobile_content] = useState<detail_js.detailsMobileContent>(detail_js.default_details_mobile_content);
    const [mobile_details, set_mobile_details] = useState<detail_js.mobileDetails>(detail_js.default_mobileDetails);
    const [is_loading, set_is_loading] = useState(true);
    const [vesselDetailsTab, setVesselDetailsTab] = useState<string>("");

    const fetchMobileContent = async () => {
        const page_content = await get_page_content("details_mobile");
        set_mobile_content((prevLoginContent) => ({
              ...prevLoginContent,
              ...page_content,
            }));
        const id = { "id": contentId }
        const res = await post_data("/get_MercuryMobileDetails", id);
        const mobile_temp = {
          ...res,
          device_id: contentId,
        };
        set_mobile_details(mobile_temp);
    }
    const fetchVesselContent = async () => {
        const content_required = detailsContentType.split('-');
        if(content_required.length > 1){
            setVesselDetailsTab(content_required[1]);
        }
    }

    const fetchContent = async () => {
        try {
            if(detailsContentType.includes('mobile')){
                await fetchMobileContent();
            }
            else if(detailsContentType.includes('vessel')){
                await fetchVesselContent();
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
       const fetchData = async () => {
           try {
             if(is_loading){
                showLoadingPanel('', true);
             }
             await fetchContent();
           } catch (error) {
             console.error(error);
           } finally {
             set_is_loading(false);
             hideLoadingPanel('');
           }
         };

         fetchData();
    }, []);
    return (
    <div className="details_page">
        {is_loading ? (
            <p>Loading...</p>
        ) : detailsContentType.includes('mobile') ? (
            <MobileDetails breadcrumbs={breadcrumbs+"|DetailsPage"} mobile_content={mobile_content} mobile_info={mobile_details} />
        ): detailsContentType.includes('vessel') ? (
            <VesselDetails breadcrumbs={breadcrumbs+"|DetailsPage"} display_tab={vesselDetailsTab}/>
        ) : null}
    </div>
    );
};

export default DetailsPage;