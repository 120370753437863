import React, { useMemo } from 'react';
import { session_user_uuid, session_parent_uuid } from '../session.js';
import CustomDatePicker from '../../Components/CustomDatePicker';
import { post_data, get_page_content } from '../API_Calls.js';
import { createRoot } from 'react-dom/client';
import * as SF from '../ModuleFunctions/SSAS_Functions.js';
import { MaritraceUser } from './UserData';
import { v4 as uuidv4 } from 'uuid';

export type ssasContent = {
  ssas_dashboard_tab_title: string;
  ssas_dashboard_tab_table1_title: string;
  ssas_dashboard_tab_table1_column: string;
  ssas_dashboard_tab_table2_title: string;
  ssas_dashboard_tab_table2_column: string;
  ssas_schedule_tab_title: string;
  ssas_schedule_tab_table_column: string;
  ssas_contacts_tab_title: string;
  ssas_contacts_tab_table_column: string;
  ssas_event_tab_title: string;
  ssas_event_tab_table_column: string;
  ssas_vessel_tab_title: string;
  ssas_vessel_tab_table_column: string;
}

export let defaultSsasContent: ssasContent = {
  ssas_dashboard_tab_title: "",
  ssas_dashboard_tab_table1_title: "",
  ssas_dashboard_tab_table1_column: "",
  ssas_dashboard_tab_table2_title: "",
  ssas_dashboard_tab_table2_column: "",
  ssas_schedule_tab_title: "",
  ssas_schedule_tab_table_column: "",
  ssas_contacts_tab_title: "",
  ssas_contacts_tab_table_column: "",
  ssas_event_tab_title: "",
  ssas_event_tab_table_column: "",
  ssas_vessel_tab_title: "",
  ssas_vessel_tab_table_column: "",
};

export async function fetchSSASContent(){
    const page_content = await get_page_content("ssas");
    defaultSsasContent = page_content;
    return defaultSsasContent;
}

export interface SSASAlert {
  alert_uuid: string;
  start_date: Date;
  end_date: Date | null;
  vessel_info: SSASVessel;
}

export async function getAlertsData(){
    const parameters = {
        "parent_uuid" : session_parent_uuid,
    }

    const res = await post_data("/getSSASAlert_List", parameters);
    let res_data: SSASAlert[] = res["ssasAlerts_list"];
    return res_data;
}

export async function getAlertsArchiveData(alert_uuids, vessel_uuids){
    const parameters = {
        "parent_uuid" : session_parent_uuid,
    }

    const res = await post_data("/getSSASAlertArchive_List", parameters);
    let res_data: SSASAlert[] = res["SSASAlerts_list"];
    return res_data;
}

export interface SSASVessel {
  ssas_vessels_uuid: string;
  tracked_vessel_uuid: string;
  user_uuid: string;
  parent_uuid: string;
  current_status: string;
  email_address: string;
  vessel_email: string;
  vessel_name: string;
  vessel_imo: string;
  vessel_mmsi: string;
  geopoint: string;
  lat: Double | null;
  lon: Double | null;
  //LOCAL
  next_test_date: Date | null;
}

export let defaultSsasVessel: SSASVessel = {
  ssas_vessels_uuid: "",
  tracked_vessel_uuid: "",
  user_uuid: "",
  parent_uuid: "",
  current_status: "",
  email_address: "",
  vessel_name: "",
  vessel_imo: "",
  vessel_mmsi: "",
  geopoint: "",
  lat: null,
  lon: null,
  next_test_date: null
};

export async function getSSASVesselData(ssas_vessel_uuids, tracked_vessel_uuids, status){
     const parameters = {
        "sass_vessel_uuid" : ssas_vessel_uuids,
        "tracked_vessel_uuid" : tracked_vessel_uuids,
        "status": status,
        "parent_uuid" : session_parent_uuid
    }
    const res = await post_data("/getSSASVessel_List", parameters);
    let res_data: SSASVessel[] = res["ssasVessel_list"];
    return res_data;
}

export async function getSSASVessel_nextScheduledTests(ssas_vesselList){
    let ssas_vessel_uuids = ssas_vesselList.map(item => item.ssas_vessels_uuid);
    let scheduledTests = await getSSASScheduledTests([], ssas_vessel_uuids, "", false, false, false);
    const earliestScheduledTests = scheduledTests.reduce((acc, test) => {
        const uuid = test.ssas_vessel_uuid;
        const isEarlier = !acc[uuid] || new Date(test.schedule_date) < new Date(acc[uuid].schedule_date);
        if (isEarlier) acc[uuid] = test;
        return acc;
    }, {});

    // Update the vessel list with the earliest schedule_date, if available
    ssas_vesselList.forEach((item) => {
        if (earliestScheduledTests[item.ssas_vessels_uuid]) {
            item.next_test_date = earliestScheduledTests[item.ssas_vessels_uuid].schedule_date;
        }
    });
    return ssas_vesselList;
}

export interface SSASScheduledTests {
    schedule_uuid: string;
    ssas_vessel_uuid: string;
    user_uuid: string;
    parent_uuid: string;
    current_status: string;
    schedule_date: Date | null;
    schedule_by: string;
    test_active: bool;
    started_date: Date | null;
    started_by: Date | null;
    completed_date: Date | null;
    completed_by: Date | null;
    deleted: Date | null;
    deleted_by: Date | null;
    // LOCAL
    vessel_info: SSASVessel | null;
    expand: bool;
}

export let defaultSsasScheduledTests: SSASScheduledTests = {
    schedule_uuid: "",
    ssas_vessel_uuid: "",
    user_uuid: "",
    parent_uuid: "",
    current_status: "",
    schedule_date: null,
    schedule_by: "",
    test_active: false,
    started_date: null,
    started_by: null,
    completed_date: null,
    completed_by: null,
    deleted: null,
    deleted_by: null,
    vessel_info: null,
    expand: false
}

export async function getSSASScheduledTests(schedule_uuid, ssas_vessel_uuids, status, include_completed, include_deleted, within_24){
    const parameters = {
        "parent_uuid" : session_parent_uuid,
        "schedule_uuid" : schedule_uuid,
        "sass_vessel_uuid" : ssas_vessel_uuids,
        "status" : status,
        "include_completed" : include_completed,
        "include_deleted" : include_deleted,
        "within_24" : within_24
    }

    const res = await post_data("/getSSASScheduledTest_List", parameters);
    let res_data: SSASScheduledTests[] = res["ssasScheduledTest_list"];
    return res_data;
}

export interface SSASContact {
 archive_contact_uuid: string;
 contact_uuid: string;
 user_uuid: string;
 parent_uuid: string;
 user_name: string;
 user_company: string;
 user_role: string;
 user_email: string;
 user_phone: string;
 user_sms: string;
 active_email: bool;
 active_screen: bool;
 test_email: bool;
 test_screen: bool;
 internal_bool: bool;
 master: bool;
 /* Local */
 priority_level: int;
 expand: boolean;
 relation: SSASAffiliation[] | null;
}

export let defaultSsasContact: SSASContact = {
    archive_contact_uuid: "",
    contact_uuid: "",
    user_uuid: "",
    parent_uuid: "",
    user_name: "",
    user_company: "",
    user_role: "",
    user_email: "",
    user_phone: "",
    user_sms: "",
    active_email: false,
    active_screen: false,
    test_email: false,
    test_screen: false,
    internal_bool: false,
    master: false,
    /* Local */
    priority_level : 0,
    expand: false,
    relation: null
}

export async function getContactData(contact_uuids, vessel_uuids, byUserUuid = false){
     let user_uuid = null;
     if(byUserUuid) user_uuid = session_user_uuid;

     const parameters = {
        "contact_uuid" : contact_uuids,
        "user_uuid" :  user_uuid,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false
    }

    const res_analysis = await post_data("/getSSASContact_List", parameters);
    let res_data: SSASContact[] = res_analysis["ssasContact_list"].map(contact => ({
        ...contact,
        expand: false
    }));
    return res_data;
}

export interface SSASEvent {
 events_logs_uuid: string;
 alert_details: SSASAlert;
 vessel_details: SSASVessel;
 test_details: SSASScheduledTests;
 contact_details: SSASContact;
 log_date: Date | null;
 log_type: string;
 log_mode: string;
 log_details: string;
 completed: Date | null;
 completed_by: string;
}

export async function getEventData(event_uuids, alert_uuids, contact_uuids, tracked_vessel_uuids, ssas_vessel_uuids, include_deleted){
    const parameters = {
        "events_logs_uuid" : event_uuids,
        "alert_uuid" : alert_uuids,
        "contact_uuid" : contact_uuids,
        "ssas_vessel_uuid" : ssas_vessel_uuids,
        "tracked_vessel_uuid" : tracked_vessel_uuids,
        "user_uuid" :  null,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : include_deleted,
    }

    const res_analysis = await post_data("/getSSASEventLog_List", parameters);
    let res_data: SSASEvent[] = res_analysis["ssasEventLog_list"];

    return res_data;
}

export interface SSASAffiliation {
    link_ssas_vessels_contacts_uuid: string;
    contact_uuid: string;
    ssas_vessel_uuid: string;
    priority_level: number;
    deleted: Date | null;
    deleted_by: string;
    active_email: bool;
    active_screen: bool;
    test_email: bool;
    test_screen: bool;
}

export async function getAffiliationData(priority_levels, contact_uuids, ssas_vessel_uuids){
    const parameters = {
       "priority_levels": priority_levels,
       "contact_uuid": contact_uuids,
       "ssas_vessel_uuid": ssas_vessel_uuids
    };

    const res = await post_data("/getSSASAffiliation_List", parameters);
    let res_data: SSASAffiliation[] = res["ssasAffiliation_list"];
    return res_data;
}

let scheduleTestVessel: SSASVessel;
let scheduleTestContact: SSASContact;
export function scheduleNewTest(ssasVessel, contact, containerId){
    scheduleTestVessel = ssasVessel;
    scheduleTestContact = contact;

    var parent = document.getElementById(containerId);

    let popup_overlay = document.createElement('div');
    popup_overlay.className = 'overlay';
    popup_overlay.id = 'ssas_schedule_test_date_popup';
    popup_overlay.onclick = function() {
        popup_overlay.remove();
    };

    let popup_content = document.createElement('div');
    popup_content.className = 'centered-content';
    popup_content.onclick = function(e) {
         e.stopPropagation();
     };
    let customDatePickerContainer = document.createElement('div');

    const root = createRoot(
        customDatePickerContainer
    );
    const today = new Date();
    root.render(<CustomDatePicker
        breadcrumbs="SSASTab|DatePicker"
        startDate={null}
        endDate={null}
        minimumDate={today.toISOString()}
        maximumDate={null}
        range={false}
        include_time={true}
        call_back_func={(returnDate) => handleScheduleTestDatePicker(returnDate, "SSASTab|DatePicker")}
    />);

    popup_content.appendChild(customDatePickerContainer);
    popup_overlay.appendChild(popup_content);
    parent.appendChild(popup_overlay);
}

async function handleScheduleTestDatePicker(returnDate, breadcrumbs){
    if(scheduleTestVessel != null && scheduleTestContact != null){
        let parameters= {
            schedule_uuid: uuidv4(),
            ssas_vessel_uuid: scheduleTestVessel.ssas_vessels_uuid,
            user_uuid: session_user_uuid,
            parent_uuid: session_parent_uuid,
            current_status: "PENDING",
            schedule_date: returnDate.replace(" ", "T"),
            schedule_by: scheduleTestContact.contact_uuid,
            test_active: false,
            completed_date: null,
            completed_by: null,
            deleted: null,
            deleted_by: "",
        }
        let result = await postSSASSchedule([parameters]);
        // this should probably be managed better
        if(result) window.location.reload();
    }
}

export async function ActivateSSASTracking(vessel_uuid, vessel_email){
    const parameters = {
        "tracked_vessel_uuid" : vessel_uuid,
        "parent_account_uuid" : session_parent_uuid,
        //"vessel_uuid" :  null,
        "search_term" : "",
        "only_basic_info" : false,
        "is_deleted" : false,
    }

    const res = await post_data("/get_TrackedVessels", parameters);

    if(res === null && res["vessel"] === null && res["tracked_vessel_uuid"] === null && res["tracked_vessel_uuid"] === "" ){
        return false;
    }
    const trackedRes = res[0];

    const contactParameters = {
        "contact_uuid" : [],
        "user_uuid" :  session_user_uuid,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false
    }

    const resContact = await post_data("/getSSASContact_List", contactParameters);

    let userContact: SSASContact = defaultSsasContact;
    let newVessel;
    let userContactList: SSASContact[] = resContact["ssasContact_list"];
    if(userContactList === null || userContactList.length === 0){
        userContact = await insertNewSSASContact();
    }
    else{
        userContact = userContactList[0];
    }

    if(userContact != null){
        let newAffiliation;
        let newSSASVesselUuid = uuidv4();
        let tracked_imo = "";
        let tracked_mmsi = "";
        if(trackedRes["vessel"] != null && trackedRes["vessel"]["imo"] != null && trackedRes["vessel"]["imo"] != ""){
            tracked_imo = trackedRes["vessel"]["imo"];
        }
        else if(trackedRes["vessel"] === null && trackedRes["vessel"]["mmsi"] != null && trackedRes["vessel"]["mmsi"] != ""){
            tracked_mmsi = trackedRes["vessel"]["mmsi"];
        }
        else{
            console.log("ActivateTracking: Tracked vessel imo and mmsi are empty");
            return false;
        }

        newVessel = await insertNewSSASVessel(newSSASVesselUuid, trackedRes["tracked_vessel_uuid"], tracked_imo, tracked_mmsi, vessel_email);

        if(newVessel != null){
            newAffiliation = await insertNewSSASAffiliation(userContact.contact_uuid, newSSASVesselUuid, 1);
            if(newAffiliation != null){
                return true;
            }
        }
        else{
            return false;
        }
    }
    else{
        return false;
    }
}

export async function insertNewSSASContact(addContact = null, maritraceUser = null){
    let newContact: SSASContact = defaultSsasContact;
    if(addContact){
        newContact = addContact;
    }
    else{
        let userMaritrace: MaritraceUser;
        if(maritraceUser) userMaritrace = maritraceUser;
        else {
            const maritrace_user_parameters = {
                "parent_account_uuids" :  [session_parent_uuid],
                "user_account_uuids" : [session_user_uuid],
            }

            const res_user = await post_data("/get_MariTraceUserAccount", maritrace_user_parameters);

            if(res_user.length <= 0){
                console.log("MariTrace user not found");
                return null;
            }
            else{
                userMaritrace = res_user[0];
            }
        }

        newContact.archive_contact_uuid = uuidv4();
        newContact.contact_uuid = uuidv4();
        newContact.user_uuid = session_user_uuid;
        newContact.parent_uuid = session_parent_uuid;
        newContact.user_name = ""+userMaritrace.first_name+" "+userMaritrace.last_name;
        newContact.user_company = userMaritrace.maritrace_parent_account.parent_account_name;
        newContact.user_email = userMaritrace.email;
        newContact.internal_bool = true;
    }
    newContact.user_uuid = newContact.user_uuid !== "" ? newContact.user_uuid : null;
    newContact.parent_uuid = newContact.parent_uuid !== "" ? newContact.parent_uuid : session_parent_uuid;
    newContact.deleted = newContact.deleted !== "" ? newContact.deleted : null;
    newContact.deleted_by = newContact.deleted_by !== "" ? newContact.deleted_by : session_parent_uuid;
    const insertContact = {
        "contact_list" : [newContact]
    }
    const result = await upsertSSASContact(insertContact);

    if(result.is_successful){
        return newContact;
    }
    else{
        return null;
    }
}

export async function upsertSSASContact(contactDetails){
    const res_insert_contact = await post_data("/upsertSSASContact", contactDetails);

    return res_insert_contact;
}

export async function insertNewSSASVessel(ssas_vessels_uuid, tracked_uuid, tracked_imo, tracked_mmsi, vessel_email){
    const newVessel = {
        "vessel_list" : [{
            "ssas_vessels_uuid" : ssas_vessels_uuid,
            "tracked_vessel_uuid" : tracked_uuid,
            "user_uuid" : session_user_uuid,
            "parent_uuid" : session_parent_uuid,
            "current_status" : "SSAS ENABLED",
            "vessel_imo" : tracked_imo,
            "vessel_mmsi" : tracked_mmsi,
            "vessel_email" : vessel_email
        }]
    }

    const result = await upsertSSASVessel(newVessel);
    if(result.is_successful){
        return newVessel;
    }
    else{
        return null;
    }
}

export async function upsertSSASVessel(vesselDetails){
    const resSSASVessel = await post_data("/upsertSSASVessel_List", vesselDetails);

    return resSSASVessel;
}

export async function insertNewSSASAffiliation(contact_uuid, ssas_vessel_uuid, priority){
    const newAffiliation = {
        "SSASAffiliation_list": [{
            "link_ssas_vessels_contacts_uuid" : uuidv4(),
            "contact_uuid": contact_uuid,
            "ssas_vessel_uuid": ssas_vessel_uuid,
            "priority_level": priority,
            "deleted": null,
            "deleted_by": null
        }]
    }
    const result = await upsertSSASAffiliation(newAffiliation);
    if(result.is_successful){
        return newAffiliation;
    }
    else{
        return null;
    }
}

export async function upsertSSASAffiliation(linkDetails){
    const resSSASVessel = await post_data("/upsert_SSASAffiliation_List", linkDetails);
    return resSSASVessel;
}

export async function postSSASSchedule(newScheduledTests){
    const newTestParameters = {"test_list" : newScheduledTests}

    const result = await upsertSSASSchedule(newTestParameters);
    if(result.is_successful){
        return newTestParameters;
    }
    else{
        return null;
    }
}

async function upsertSSASSchedule(schedule_test){
    const resScheduleTest = await post_data("/upsertSSASScheduledTest", schedule_test);
    return resScheduleTest;
}

// build event object function? alertDetails, ssasVessel, ssasSchedule, ssasContact
async function postSSASEventLog(ssasEventList: SSASEvent[]){
    let log_list = { "event_list" : [] };

    ssasEventList.forEach(item => {
        if(ssasEventList.events_logs_uuid == null) ssasEventList.events_logs_uuid = uuidv4();
        log_list.event_list.push(ssasEventList);
    })

    const result = await insertSSASEventLog(log_list);
    if(result.is_successful){
        return "logged";
    }
    else{
        return "log failed";
    }
}

async function insertSSASEventLog(parameters){
    const res = await post_data("/insertSSASEventLog", parameters);
    return res;
}