import { Container, Row, Col, Tabs, Tab, Button, Badge, Form, InputGroup } from 'react-bootstrap';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import { session_user_uuid, session_parent_uuid } from '../session.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomCheckbox from '../../Components/CustomCheckbox';
import { sendDataToParent } from './Iframe_Handler.js';
import { MaritraceUser, getMaritraceUsers } from '../ComponentData/UserData.js'
import * as SP from '../ComponentData/SSAS_Page.js'
import * as G from '../Other/General.js'
import { handleNotificationRender } from './Notifications.js';
import { v4 as uuidv4 } from 'uuid';

//export tabColumnEditables:[string, React.ReactNode[]][] = ["Priority Level", ]

export function getAlertTableData(data, columns_names) {
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const active_alerts_columns = [
        columnHelper.accessor('vessel_info.vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('vessel_info.current_status', {
            header: <span className="center_header">{headers[1] ?? 'STATUS'}</span>,
            filterFn: 'equals',
            cell: info => getAlertStatusElement(info.getValue()?.toUpperCase()),
        }),
        columnHelper.accessor('locate', {
            header: <span className="right_header">{headers[2] ?? 'LOCATE'}</span>,
            enableColumnFilter: false,
            cell: info => (
                <p class="align-right"><button className="btn fas pt1-5"
                    onClick={() => flyToMobile([info.row.original.vessel_info.lon, info.row.vessel_info.original.lat])}
                    title="Centre map on vessel">
                    <FontAwesomeIcon icon={['fas', 'crosshairs']} />
                </button></p>
            ),
        }),
    ];

    return active_alerts_columns;
}

export function getScheduleTableData(data, is_dash, callbacks, columns_names) {
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");
    let schedule_columns = [];

    if(!is_dash){
       schedule_columns.push(columnHelper.accessor('expand', {
         header: 'EXPAND',
         enableColumnFilter: false,
         cell: info => {
           const isExpanded = info.getValue(); // Get the current value for this cell
           return (
             <p className="align-center">
             <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
               onClick={() => callbacks.toggleExpandTestSchedule(info.row.original.schedule_uuid)} >
               <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
             </button></p>
           );
         }
       }));
    }
    schedule_columns.push(columnHelper.accessor('schedule_date', {
       header: 'DATE (UTC)',
       filterFn: 'includesString',
       cell: info => info.getValue().replace('T', ' ').substring(0, 16),
    }));

    schedule_columns.push(columnHelper.accessor('vessel_info.vessel_name', {
       header: 'VESSEL',
       filterFn: 'includesString',
       cell: info => info?.getValue() ? info.getValue() : "UNKNOWN",
    }));

    if(!is_dash){
    　 schedule_columns.push(columnHelper.accessor('schedule_by', {
         header: 'Scheduled by',
         filterFn: 'includesString',
         cell: info => info.getValue(),
       }));
    }
    schedule_columns.push(columnHelper.accessor('current_status', {
        header: <span className="center_header">OUTCOME</span>,
       filterFn: 'equals',
       cell: info => {
        return getScheduleStatusElement(info.getValue());
       }
    }));
    schedule_columns.push(columnHelper.accessor('locate', {
       header: <span className="right_header">LOCATE</span>,
       enableColumnFilter: false,
       cell: info => <p class="align-right"><button className="btn fas pt1-5" onClick={() => flyToMobile([info.row.original.vessel_info.lon, info.row.original.vessel_info.lat])} title="Centre map on vessel" value="mobile_find">
           <FontAwesomeIcon icon="crosshairs"/>
       </button></p>,
    }));

    return schedule_columns;
}

export function getContactsTableData(data, callbacks, columns_names, master_table = false){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const contacts_columns = [
      columnHelper.accessor('expand', {
        header: 'Expand',
        enableColumnFilter: false,
        cell: info => {
          const isExpanded = info.getValue(); // Get the current value for this cell
          return (<p className="align-center">
            <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
              onClick={() => callbacks.toggleExpandContact(info.row.original.contact_uuid)} >
              <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
            </button></p>
          );
      }}),
      columnHelper.accessor('internal_bool', {
          header: 'Internal',
          enableColumnFilter: false,
          cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();

            if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "internal"} initialState={value}
            three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "internal_bool", master_table)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('master', {
        header: 'Master',
        enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let disable_box = false;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;

           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "master"} initialState={value}
           three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "master", master_table)} disabled={disable_box} /></p>);
      }}),
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          editable: true,
          cell: info => {
              return <input defaultValue={String(info.getValue() ?? "")}
                type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_name")} />
          }
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
              type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_company")} />
        }
      }),
      columnHelper.accessor('user_role', {
        header: 'Position',
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
              type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_role")} />
        }
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
              type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_email")} />
        }
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
              type="number" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_phone")} />
        }
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
              type="number" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_sms")} />
        }
      }),
      columnHelper.accessor('active_email', {
        header: 'Active Email',
           enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let disable_box = false;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;
           //disable_box = true;
           //value = 0
           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_email"} initialState={value}
           three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "active_email", master_table)} disabled={disable_box} /></p>);
      }}),

      columnHelper.accessor('active_screen', {
        header: 'Active Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();
            if(info.row.original.master !== true){
                disable_box = true;
                value = 0;
            }
            else if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_screen"} initialState={value}
            three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "active_screen", master_table)} disabled={disable_box} /></p>);
        }}),

      columnHelper.accessor('test_email', {
        header: 'Test Email',
        enableColumnFilter: false,
        cell: info => {
             const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_email"} initialState={value}
             three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "test_emsil", master_table)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('test_screen', {
        header: 'Test Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_screen"} initialState={value}
             three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "test_screen", master_table)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('delete_able', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.row.original.master === false ? (
          <Button className="delete_link" variant="light" onClick={() => updateContactFromTable(info.row.original, "", "", "DELETE")}>DELETE</Button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getVesselTabContactsTableData(data, callbacks, columns_names, ssas_vessel_uuid){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    /*
        columnHelper.accessor('expand', {
                header: 'Expand',
                enableColumnFilter: false,
                cell: info => {
                  const isExpanded = info.getValue(); // Get the current value for this cell
                  return (<p className="align-center">
                    <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
                      onClick={() => callbacks.toggleExpandContact(info.row.original.contact_uuid)} >
                      <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
                    </button></p>
                  );
              }}),

      columnHelper.accessor('internal_bool', {
          header: 'Internal',
          enableColumnFilter: false,
          cell: info => {
            const row_id = info.row.original.row_id;
            let value = info.getValue();

            if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "internal"} initialState={value}
            three_states={false} onChange={null} disabled={true} /></p>);
        }}),
      columnHelper.accessor('master', {
        header: 'Master',
        enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;

           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "master"} initialState={value}
           three_states={false} onChange={null} disabled={true} /></p>);
      }}),
    */

    const contacts_columns = [
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          editable: true,
          cell: info => info.getValue()
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_role', {
        header: 'Position',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('active_email', {
        header: 'Active Email',
           enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let disable_box = false;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;
           //disable_box = true;
           //value = 0
           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_email"} initialState={value}
           three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "active_email", ssas_vessel_uuid)} disabled={disable_box} /></p>);
      }}),

      columnHelper.accessor('active_screen', {
        header: 'Active Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();
            if(info.row.original.master !== true){
                disable_box = true;
                value = 0;
            }
            else if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_screen"} initialState={value}
            three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "active_screen", ssas_vessel_uuid)} disabled={disable_box} /></p>);
        }}),

      columnHelper.accessor('test_email', {
        header: 'Test Email',
        enableColumnFilter: false,
        cell: info => {
             const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_email"} initialState={value}
             three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "test_email", ssas_vessel_uuid)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('test_screen', {
        header: 'Test Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_screen"} initialState={value}
             three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "test_screen", ssas_vessel_uuid)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('delete_able', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.row.original.master === false ? (
          <Button className="delete_link" variant="light" onClick={() => updateContactFromTable(info.row.original, "", "", "DELETE")}>DELETE</Button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getContactsCascadeTableData(data, callbacks, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const contacts_columns = [
      columnHelper.accessor('priority_level', {
        header: 'Priority Level',
        enableColumnFilter: false,
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
                type="number" onBlur={(e) => changePriority(info.row.original, String(info.getValue() ?? ""), e.target.value, "priority_level")} />
        }
      }),
      columnHelper.accessor('internal_bool', {
          header: 'Internal',
          enableColumnFilter: false,
          cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();

            if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "internal"} initialState={value}
            three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "internal_bool", false)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          cell: info => info.getValue()
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('', {
        header: 'Log Contact',
        enableColumnFilter: false,
        cell: info => <button className="log_link"  onClick={() => logContactAttempt(info.row.original.user_name , info.row.original.contact_uuid)}>LOG</button>
        }),
      columnHelper.accessor('', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.row.original.master === false ? (
          <Button className="delete_link" variant="light" onClick={() => updateContactFromTable(info.row.original, "", "", "DELETE")}>DELETE</Button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getEventsLogsTableData(data, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const events_columns = [
        columnHelper.accessor('log_date', {
            header: 'LOG DATE',
            cell: info => info.getValue().replace('T', ' ').substring(0, 16),
        }),
        columnHelper.accessor('log_type', {
            header: 'LOG TYPE',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('log_mode', {
            header: <span className="center_header">MODE</span>,
            filterFn: 'equals',
            cell: info => getLogStatusElement(info.getValue()),
        }),
        columnHelper.accessor('contact_details.user_email', {
            header: 'EMAIL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('contact_details.user_name', {
            header: 'NAME',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('contact_details.user_company', {
            header: 'COMPANY',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('alert_details.current_status', {
            header: 'OUTCOME',
            cell: info => getAlertStatusElement(info.getValue()),
        }),
        columnHelper.accessor('alert_details.vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('alert_details.locate', {
            header: <span className="right_header">LOCATE</span>,
            enableColumnFilter: false,
            cell: info => (
                <p class="align-right"><button className="btn fas pt1-5" title="Center map on vessel"
                    onClick={() => flyToMobile([info.row.original.alert_details.lon, info.row.original.alert_details.lat])} >
                    <FontAwesomeIcon icon={['fas', 'crosshairs']} />
                </button></p>
            ),
        }),
    ];

    return events_columns;
};

export function getVesselsTableData(data, expand_contacts, expand_uuid, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const vessels_columns = [
        columnHelper.accessor('vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('current_status', {
            header: <span className="center_header">STATUS</span>,
            filterFn: 'equals',
            cell: info => getAlertStatusElement(info.getValue()),
        }),
    ];
    vessels_columns.push(
        columnHelper.accessor('next_test_date', {
         header: <span className="center_header">NEXT TEST (UTC)</span>,
         cell: info => {
               const value = info.getValue();
               let temp = "";
               if(value != null ){
                    temp = value ? value.replace('T', ' ').substring(0, 16) : "";
               }
               else{
                    temp = "UNSET";
               }
               return (<span className="center_text">{temp}</span>);
             },
        }),
        columnHelper.accessor('locate', {
         header: <span className="right_header">LOCATE</span>,
         enableColumnFilter: false,
         cell: info => (
             <p class="align-right"><button className="btn fas pt1-5" title="Center map on vessel"
                 onClick={() => flyToMobile([info.row.original.lon, info.row.original.lat])} >
                 <FontAwesomeIcon icon={['fas', 'crosshairs']} />
             </button></p>
         ),
        })
    );

    return vessels_columns;
};

export function getTestVesselInfo(testList, vesselList){
    const vesselMap = new Map(vesselList.map(v => [v.ssas_vessels_uuid, v]));

    const updatedTestList = testList.map(item => {
        item.vessel_info = vesselMap.get(item.ssas_vessel_uuid);
        return item;
    });
    return updatedTestList;
};

export async function combineContactAffiliation(contactList, affiliationList) {

    contactList.forEach(item => {
        let affiliation = affiliationList.find(x => x.contact_uuid === item.contact_uuid);

        if (affiliation) {
            item.priority_level = affiliation.priority_level;
            if(!item.active_email) item.active_email = affiliation.active_email;
            if(!item.test_email) item.test_email = affiliation.test_email;
            if(!item.active_screen) item.active_screen = affiliation.active_screen;
            if(!item.test_screen) item.test_screen = affiliation.test_screen;
            item.relation = [affiliation];
        }
    });

    return contactList; // Optional, if you want to return the modified list
}

export const flyToMobile = (position: number[]) => {
  sendDataToParent({ item:'fly_to_location', value: position})
};

export function getRelevantSSAS_Status(code, type) {
    if (type === "schedule") {
        switch (code) {
            case 0:
                return "NOT APPLICABLE";
            case 1:
                return "PENDING";
            case 2:
                return "SUCCESSFUL";
            case 3:
                return "UNSUCCESSFUL";
            default:
                return "UNKNOWN";
        }
    }else if (type === "event") {
         switch (code) {
            case 0:
                 return "NOT APPLICABLE";
             case 1:
                 return "LIVE";
             case 2:
                 return "TEST";
             default:
                 return "UNKNOWN";
         }
     }else if (type === "vessels") {
           switch (code) {
              case 0:
                   return "NOT ENABLED";
               case 1:
                   return "SSAS ENABLED";
               case 1:
                   return "SSAS FIRING IN TEST MODE";
               case 2:
                   return "SSAS FIRING";
               default:
                   return "UNKNOWN";
           }
       }

    return "UNKNOWN";
}

export function getAlertStatusElement(status_string){
    if(status_string === "SSAS FIRING IN TEST MODE"){
        return <Badge id='current_ssas_status' bg='warning'>SSAS FIRING IN TEST MODE</Badge>;
    }else if(status_string === "SSAS FIRING"){
        return <Badge id='current_ssas_status' bg='danger'>SSAS FIRING</Badge>;
    } else if (status_string === "SSAS ACTIVE" || status_string === "SSAS ENABLED"){
        return <Badge id='current_ssas_status' bg='success'>SSAS ENABLED</Badge>;
    } else if (status_string === "SSAS ACTIVATION REQUESTED"){
        return <Badge id='current_ssas_status' bg='secondary'>SSAS ACTIVATION REQUESTED</Badge>;
    } else if (status_string === "SSAS IN TEST MODE"){
        return <Badge id='current_ssas_status' bg='primary'>SSAS IN TEST MODE</Badge>;
    } else if (status_string === "NOT ENABLED"){
        return <Badge id='current_ssas_status' bg='light'>NOT ENABLED</Badge>;
    } else{
        return <Badge id='current_ssas_status' bg='dark'>UNKNOWN</Badge>;
    }
}

export function getScheduleStatusElement(status_string){
    if(status_string === "NOT APPLICABLE"){
        return <Badge bg='secondary'>NOT APPLICABLE</Badge>;
    }else if(status_string === "PENDING"){
        return <Badge bg='primary'>PENDING</Badge>;
    }else if(status_string === "SUCCESSFUL"){
        return <Badge bg='success'>SUCCESSFUL</Badge>;
    }else if(status_string === "UNSUCCESSFUL"){
        return <Badge bg='danger'>UNSUCCESSFUL</Badge>;
    }else if(status_string === "TEST UNDERWAY"){
        return <Badge bg='warning'>UNSUCCESSFUL</Badge>;
    }else{
        return <Badge bg='dark'>UNKNOWN</Badge>;
    }
}

export function getLogStatusElement(status_string){
    if(status_string === "NOT APPLICABLE"){
        //return <span className='badge bg-secondary'>NOT APPLICABLE</span>;
        return <Badge bg='secondary'>NOT APPLICABLE</Badge>;
    }else if(status_string === "LIVE"){
        //return <span className='badge bg-danger'>LIVE</span>;
        return <Badge bg='danger'>LIVE</Badge>;
    }else if(status_string === "TEST"){
        //return <span className='badge bg-warning'>TEST</span>;
        return <Badge bg='warning'>TEST</Badge>;
    }else {
        //return <span className='badge bg-dark'>UNKNOWN</span>;
        return <Badge bg='dark'>UNKNOWN</Badge>;
    }
}

export function expandScheduledTestContent(testDate){
    return (<tr>
                <td></td>
                <td colSpan={5}>
                  <Container className="test_schedule_expand">
                    <Row>
                        <Col>
                            <p className="text">Started (UTC):</p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="testSchedule.startedUTC">
                                <Form.Control type="text" placeholder="Started (UTC)" value={testDate.schedule_date ? testDate.schedule_date.replace('T', ' ').substring(0, 16) : ""}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button onClick={() => changeTestStatus()} variant="primary">Start Test</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text">Started by:</p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="testSchedule.startedBy">
                                <Form.Control type="text" placeholder="Started by" value={testDate.schedule_by ? testDate.schedule_by : ""}/>
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           <p className="text">Completed:</p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="testSchedule.completed">
                                <Form.Control type="text" placeholder="Completed" value={testDate.completed_date ? testDate.completed_date.replace('T', ' ').substring(0, 16) : ""}/>
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text">Completed by:</p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="testSchedule.completedBy">
                                <Form.Control type="text" placeholder="Completed by" value={testDate.completed_by ? testDate.completed_by : ""}/>
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text">Notes:</p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="testSchedule.notes">
                                <Form.Control type="text" placeholder="Notes" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="light">Print PDF</Button>
                        </Col>
                    </Row>
                  </Container>
                </td>
              </tr>);
}

export function validateVesselEmailAddress(){
    let email_input = document.getElementById('vessel_email_for_ssas');
    if(email_input && email_input.value != "") return [true, email_input.value];
    else{
        let error_notice = document.getElementById('vessel_email_error_notice');
        error_notice.style.display = 'block';
        return [false, ""];
    }
}

export function getVesselDetailsButton(activeState, handleSSASActiveButton, tracked_uuid){
    /*if(activeState === "SSAS ACTIVATION REQUESTED" || activeState === "0"){
        return (
            <Button className="SSAS_activate_button" variant="secondary" value="cancel" onClick={() => handleSSASActiveButton("Cancel", tracked_uuid)}>Cancel SSAS Request</Button>
        );
    }
    else*/ if(activeState === "SSAS ACTIVE" || activeState === "SSAS ENABLED" || activeState === "1"){
        return (
            <Button className="SSAS_activate_button" variant="light" value="deactivate" onClick={() => handleSSASActiveButton("Deactivation", tracked_uuid)}>Request SSAS Deactivation</Button>
        );
    }
    else if(activeState === "SSAS FIRING" || activeState === "2"){
        return (<>
            <Button className="SSAS_activate_button" variant="info" value="mute" onClick={() => handleSSASActiveButton("Mute test", tracked_uuid)}>Mute Alarm</Button>
            <Button className="SSAS_activate_button" variant="secondary" value="cancel" onClick={() => handleSSASActiveButton("Cancel alert", tracked_uuid)}>Cancel SSAS Alert</Button>
        </>);
    }
    else if(activeState === "SSAS FIRING IN TEST MODE" || activeState === "3"){
        return (
            <Button className="SSAS_activate_button" variant="info" value="mute" onClick={() => handleSSASActiveButton("Mute test", tracked_uuid)}>Mute Alarm</Button>
        );
    }
    else if(activeState === "SSAS IN TEST MODE" || activeState === "4"){
                return null;
    }else{
        return (
            <Button className="SSAS_activate_button" variant="light" value="activate" onClick={() => handleSSASActiveButton("Activation", tracked_uuid)}>Request SSAS Activation</Button>
        );
    }
}

export function logContactAttempt(user_name, user_id){
    const content = (
        <div>
            <Form.Check inline label="Telephone call" name="phone" type="radio" id="log_contact_phone" />
            <Form.Check inline label="Email" name="email" type="radio" id="log_contact_email" />
            <Form.Check inline label="SMS" name="sms" type="radio" id="log_contact_sms" />
            <Form.Check inline label="Voice call" name="voice_call" type="radio" id="log_contact_voice_call" />
            <Form.Check inline label="Other" name="other" type="radio" id="log_contact_other" />

            <Form.Control as="textarea" placeholder="Enter notes here"/>
        </div>
    );
    //onClick={(e) => handleDetailSave(e)}
    const buttons = (
            <div>
                <Button variant="success">Save</Button>
                <Button variant="secondary">cancel</Button>
            </div>
        );
    handleNotificationRender("log_contact_notif", "NOTIFICATION|LOGCONTACT", ["Add Contact log for Sheri Mcguire"], [content], [buttons], ["log_contact_cont"]);
}

function addSelectedContact(user){
    SP.insertNewSSASContact(null, user);
}

export async function addContactNotif(existingContacts: SSASContact[] | null = null){
    let userList = await getMaritraceUsers("", "", true);
    if(userList){
        const filteredUserList = userList.filter(item => {
            return !existingContacts.some(i => i.user_uuid === item.user_account_uuid);
        });
        if(filteredUserList){
            const content = (
                <div>
                    <Form.Select id="userContactSelect" aria-label="Select a user" onChange={e => {
                           const selectedUser = filteredUserList.find(user => user.user_account_uuid === e.target.value);
                           if (selectedUser) {
                               addSelectedContact(selectedUser);
                           }
                       }}>
                      <option></option>
                      {filteredUserList.map(user => (
                        <option value={user.user_account_uuid} >{user.first_name} {user.last_name}</option>
                      ))}
                    </Form.Select>
                </div>
            );

            const buttons = (
                <div>
                    <Button variant="secondary">Close</Button>
                </div>
            );
            handleNotificationRender("add_contact_notif", "NOTIFICATION|ADDCONTACT", ["Select user to be added"], [content], [null], ["add_contact_cont"]);
        }
    }
}

export function contactCheckboxChange(row, event, variable, is_master){
    row[variable] = row[variable] === true? false : true;
    if(is_master){
        const upsertContact = {"contact_list" : [row]}
        SP.upsertSSASContact(upsertContact);
    }
}

export async function contactAssociationCheckboxChange(row, event, variable, ssas_vessel_uuid){
    row[variable] = row[variable] === true? false : true;
    let link_uuid = uuidv4();
    if(row.relation && row.relation.length > 0){
        link_uuid = row.relation[0].link_ssas_vessels_contacts_uuid;
    }
    const newAffiliation = {
        "SSASAffiliation_list": [{
            "link_ssas_vessels_contacts_uuid" : link_uuid,
            "contact_uuid": row.contact_uuid,
            "ssas_vessel_uuid": ssas_vessel_uuid.ssas_vessels_uuid,
            "active_email": row.active_email,
            "test_email": row.test_email,
            "active_screen": row.active_screen,
            "test_screen": row.test_screen,
            "deleted": row.deleted,
            "deleted_by": row.deleted_by
        }]
    }
    console.log("newAffiliation: ", newAffiliation);
    const result = await SP.upsertSSASAffiliation(newAffiliation);
}

export async function addContactAssociation(addContact, addVessel){
    let link_uuid = uuidv4();
    if(addContact.relation != null && addContact.relation.length > 0){
        link_uuid = addContact.relation[0].link_ssas_vessels_contacts_uuid;
    }
    const newAffiliation = {
        "SSASAffiliation_list": [{
            "link_ssas_vessels_contacts_uuid" : link_uuid,
            "contact_uuid": addContact.contact_uuid,
            "ssas_vessel_uuid": addVessel.ssas_vessels_uuid,
            "active_email": addContact.active_email,
            "test_email": addContact.test_email,
            "active_screen": addContact.active_screen,
            "test_screen": addContact.test_screen,
            "priority_level": addContact.priority_level,
            "deleted": addContact.deleted,
            "deleted_by": addContact.deleted_by
        }]
    }

    const result = await SP.upsertSSASAffiliation(newAffiliation);
    // need to be removed at a later date
    if(result) window.location.reload();
}

export async function updateContactFromTable(row, old_value, new_value, column_header){
    //if(old_value === new_value) console.log("nothing changed: "+ old_value+ " - "+new_value);
    if (old_value != new_value) {
        if(column_header.toUpperCase().includes("DELETE")){
            row.deleted = new Date();
            row.deleted_by = session_user_uuid;
        }
        else{
            row[column_header] = new_value;
        }
        SP.insertNewSSASContact(row, null);
    }
}

export async function changePriority(row, old_value, new_value, column_header){
    let link_uuid = uuidv4();
    if(row.relation && row.relation.length > 0){
        link_uuid = row.relation[0].link_ssas_vessels_contacts_uuid;
    }
    if(link_uuid && G.isNumberOnly(new_value)){
        const newAffiliation = {
            "SSASAffiliation_list": [{
                "link_ssas_vessels_contacts_uuid" : link_uuid,
                "contact_uuid": row.contact_uuid,
                "ssas_vessel_uuid": row.relation[0].ssas_vessels_uuid,
                "active_email": row.active_email,
                "test_email": row.test_email,
                "active_screen": row.active_screen,
                "test_screen": row.test_screen,
                "priority_level": new_value,
                "deleted": row.deleted,
                "deleted_by": row.deleted_by
            }]
        }

        const result = await SP.upsertSSASAffiliation(newAffiliation);
    }
}

export function updateVesselEmail(ssasVessel, new_email = ""){
    if(new_email === "") new_email = document.getElementById("vessel_email_for_ssas");
    console.log("vessel_email_for_ssas: ", new_email);
    if(new_email != "" && ssasVessel.vessel_email != new_email){
        ssasVessel.vessel_email = new_email;
        const parameters = {
                "vessel_list" : [ssasVessel]}
        const result = SP.upsertSSASVessel(parameters);
    }
}

export function changeTestStatus(){
}

        /*const newTestParameters = {"test_list" : newScheduledTests}
        const result = upsertSSASSchedule(newTestParameters);


        */