import React, { useEffect } from 'react';
import { ModalProvider, useModal } from './Modal_Context';
import DetailsPage from '../Pages/Details_Page';

interface Props {
    breadcrumbs: string;
}

const MainToolBar: React.FC<Props> = (breadcrumbs) => {
  const { showModal, hideModal } = useModal();

  const openModal = () => {
    console.log("it trys");
    showModal(
      <DetailsPage breadcrumbs={breadcrumbs+"|MainToolBar"} detailsContentType={"mobile"} contentId={"item_id"} />
    );
  };
  return (
      <div id="icon_panel">
          <ul>
              <li title="Toggle the search bar">
                  <i onClick={openModal} className="fa fa-search"></i>
              </li>
              <li title="Saved Vessels" className="top_border">
                  <i className="fa fa-ship"></i>
              </li>
              <li title="Saved Vessels List">
                  <i className="fa fa-list-ul"></i>
              </li>
              <li title="Layers for the map" className="top_border">
                  <i className="fa fa-layer-group"></i>
              </li>
              <li title="Weather overlays">
                  <i className="fa fa-cloud"></i>
              </li>
              <li title="Intelligence">
                  <i className="fa fa-shield-alt"></i>
              </li>
              <li title="Routes">
                  <i className="fa fa-route"></i>
              </li>
              <li title="Draw areas on the map">
                  <i className="fa fa-pencil-alt"></i>
              </li>
              <li title="Manage alerts">
                  <i className="fa fa-exclamation-triangle"></i>
              </li>
              <li title="Messages">
                  <i className="fa fa-envelope-open-text"></i>
              </li>
              <li title="Vessel Schedules">
                  <i className="fa fa-calendar"></i>
              </li>
              <li title="Toggle Ports">
                  <i className="fa fa-anchor"></i>
              </li>
              <li title="Measure">
                  <i className="fa fa-ruler"></i>
              </li>
              <li title="Fly to...">
                  <i className="fa fa-crosshairs"></i>
              </li>
              <li title="Toggle Graticules">
                  <i className="fa fa-border-all"></i>
              </li>
              <li title="Toggle map projections">
                  <i className="fa fa-cube"></i>
              </li>
              <li title="MariTrace Data Analytics and Applications">
                  <i className="fa fa-database"></i>
              </li>
              <li title="Configure your settings">
                  <i className="fa fa-cogs"></i>
              </li>
              <li title="About MariTrace">
                  <i className="fa fa-info-circle"></i>
              </li>
              <li title="Sign out" className="top_border">
                  <i className="fa fa-sign-out-alt"></i>
              </li>
              <li title="Administrator Tools" className="top_border">
                  <i className="fa fa-toolbox"></i>
              </li>
          </ul>
      </div>
  );
};

export default MainToolBar;
