import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import * as SP from '../Javascript/ComponentData/SSAS_Page.js';
import * as SF from '../Javascript/ModuleFunctions/SSAS_Functions.js';
import { session_user_uuid, session_parent_uuid } from '../Javascript/session.js';
import { getUserUuid, getUTCTimeDifference } from '../Javascript/Other/General.js';
import CustomTable from '../Components/CustomTable';
import { Container, Row, Col, Tabs, Tab, Button, Form, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { matchSorter } from 'match-sorter';
import '../CSS/Module_CS/ssas_page.css';
import { v4 as uuidv4 } from 'uuid';
import '../CSS/Module_CS/components.css';

interface Props {
  breadcrumbs: string;
}

const SSASPage: React.FC<Props> = ({ breadcrumbs }) => {
    const [userUuid, setUserUuid] = useState<string | null>(getUserUuid().toString());
    const [is_loading, set_is_loading] = useState(false);
    const [triggerReRender, setTriggerReRender] = useState(false);
    const [ssasContent, setSsasContent] = useState<SP.ssasContent>(SP.defaultSsasContent);

    const addExternalContact = () =>{
        let emptyContact: SP.SSASContact = SP.defaultSsasContact;
        emptyContact.contact_uuid = uuidv4();
        emptyContact.internal_bool = false;
        console.log("emptyContact", emptyContact);
        setContacts((prevData: SP.SSASContact[] | null) => {
            return prevData ? [...prevData, emptyContact] : [emptyContact];
        });
    }

    const checkboxContacts = (name:string, value:boolean) => {
        console.log('I '+name+'!', value);
    };
    const deleteContact = (row_id:number) => {
        alert('Delete contact: ' + row_id);
    };

    const [expandVessel, setExpandVessel] = useState<SP.SSASVessel[] | null>(null);
    const [contactsExpandColumns, setContactsExpandColumns] = useState<ColumnDef<SP.SSASVessel>[] | null>(null);
    const toggleExpandContact = (row_id: string) => {
      if (contacts != null) {
        setContacts(prevData =>
          (prevData ?? []).map(test =>
            test.contact_uuid === row_id ? { ...test, expand: !test.expand } : test
          )
        );
      }
    };

    const [contacts, setContacts] = useState<SP.SSASContact[] | null>(null);
    const [contactsColumns, setContactsColumns] = useState<ColumnDef<SP.SSASContact>[]>(SF.getContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }, ssasContent.ssas_contacts_tab_table_column, true) as ColumnDef<SP.SSASContact>[]);
    const [affiliationList, setAffiliationList] = useState<SP.SSASAffiliation[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            if(contacts != null) {
                let contact_ids:string[] = contacts.map(contact => contact.contact_uuid);
                const res = await SP.getAffiliationData([],contact_ids,[]);
                setAffiliationList(res);
            }
        }
        fetchData();
        setContactsColumns(SF.getContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }, ssasContent.ssas_contacts_tab_table_column, true) as ColumnDef<SP.SSASContact>[]);
    },[contacts]);

    const [activeAlerts, setActiveAlerts] = useState<SP.SSASAlert[] | null>(null);
    const [activeAlertsColumns, setActiveAlertsColumns] = useState<ColumnDef<SP.SSASAlert>[]>(SF.getAlertTableData({}, ssasContent.ssas_dashboard_tab_table1_column) as ColumnDef<SP.SSASAlert>[]);

    const toggleExpandTestSchedule = (row_id: string) => {
      if(testSchedule != null){
          setTestSchedule(prevData =>
            (prevData ?? []).map(test =>
              test.schedule_uuid === row_id ? { ...test, expand: !test.expand } : test
            )
          );
      }
    };

    const [schedule, setSchedule] = useState<SP.SSASScheduledTests[] | null>(null);
    const [scheduleColumns, setScheduleColumns] = useState<ColumnDef<SP.SSASScheduledTests>[]>(SF.getScheduleTableData({}, true, {}, ssasContent.ssas_dashboard_tab_table2_column) as ColumnDef<SP.SSASScheduledTests>[]);
    const [testSchedule, setTestSchedule] = useState<SP.SSASScheduledTests[] | null>(null);
    const [testScheduleColumns, setTestScheduleColumns] = useState<ColumnDef<SP.SSASScheduledTests>[]>(SF.getScheduleTableData({}, false, { toggleExpandTestSchedule }, ssasContent.ssas_schedule_tab_table_column) as ColumnDef<SP.SSASScheduledTests>[]);
    useEffect(() => {
        setTestScheduleColumns(SF.getScheduleTableData({}, false, {toggleExpandTestSchedule}, ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASScheduledTests>[]);
    },[testSchedule]);

    const [allSSASVessels, setAllSSASVessels] = useState<SP.SSASVessel[] | null>(null);
    const [ssasVesselsColumns, setSSASVesselsColumns] = useState<ColumnDef<SP.SSASVessel>[]>(SF.getVesselsTableData({}, false, '', ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASVessel>[]);
    const [events, setEvents] = useState<SP.SSASEvent[] | null>(null);
    const [eventsColumns, setEventsColumns] = useState<ColumnDef<SP.SSASEvent>[]>(SF.getEventsLogsTableData({}, ssasContent.ssas_event_tab_table_column) as ColumnDef<SP.SSASEvent>[]);
    useEffect(() => {
        setEventsColumns(SF.getEventsLogsTableData({}, ssasContent.ssas_event_tab_table_column) as ColumnDef<SP.SSASEvent>[]);
    },[events]);


    useEffect(() => {
        const fetchData = async () => {
           try {

             if(ssasContent.ssas_dashboard_tab_title === ""){
                const res = await SP.fetchSSASContent();
                setSsasContent(res);
             }
             if(activeAlerts === null || triggerReRender){
                const res = await SP.getAlertsData();
                setActiveAlerts(res);
             }
             if(contacts === null || triggerReRender){
                const res = await SP.getContactData([],[]);
                setContacts(res);
             }
             if(allSSASVessels === null || triggerReRender){
                const res = await SP.getSSASVesselData([], [], "", );
                setAllSSASVessels(await SP.getSSASVessel_nextScheduledTests(res));
             }
             if(events === null || triggerReRender){
                const res = await SP.getEventData([], [], [], [], [], false);
                setEvents(res);
             }
             if(session_user_uuid === ""){
                getUserUuid();
             }
           } catch (error) {
             console.error(error);
           } finally {
             set_is_loading(false);
           }
         };
       fetchData();
    },[allSSASVessels, contacts, events, activeAlerts, session_user_uuid, session_parent_uuid, ssasContent]);

    useEffect(()=>{
        const fetchData = async () => {
            const restest = await SP.getSSASScheduledTests([], [], "", true, false, false);
            setTestSchedule(await SF.getTestVesselInfo(restest, allSSASVessels));

            const res = await SP.getSSASScheduledTests([], [], "", false, false, true);
            setSchedule(await SF.getTestVesselInfo(res, allSSASVessels));
        }
        if(allSSASVessels != null){
            fetchData();
        }
    }, [allSSASVessels]);
    useEffect(()=>{
        if(affiliationList != null && contacts != null) {
            contacts.map(x => {
                const foundRelation = affiliationList?.filter(j => j.contact_uuid === x.contact_uuid);
                if (foundRelation) {
                    x.relation = foundRelation;
                }
            });
        }
    }, [affiliationList]);

    const expandElementContact = useCallback((row_id: string, more_info: any) => {
        if(more_info.expand){
            let related_vessels: SP.SSASVessel[] = [];
            let ssasvesselIds:string[] = [];
            if(more_info.relation != null){
                ssasvesselIds = more_info.relation.map((i: SP.SSASAffiliation) => i.ssas_vessel_uuid);
            }
            if(allSSASVessels != null){
                related_vessels = allSSASVessels.filter(i => ssasvesselIds.includes(i.ssas_vessels_uuid));
            }
            return (<CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ContactExpandTable"} columns={ssasVesselsColumns} data={related_vessels}
            rows_displayed={related_vessels ? related_vessels.length : 0} dropdown_placeholder={"All"} expand_element={null}/>);
        }
    }, [allSSASVessels]);

    const expandElementTestSchedule = useCallback((row_id: string, more_info: any) => {
      if(more_info.expand){
          return SF.expandScheduledTestContent(more_info);
        }
        else {
            return (null);
        }
    }, []);

    return (
      <Container id="ssas_page">
        <div className="header">
            <h1>SSAS</h1>
        </div>
        {is_loading ? (
            <p>Loading...</p>
        ) : (
            <Tabs defaultActiveKey="dashboard" id="ssas_tab_cont">
                <Tab eventKey="dashboard" title={ssasContent.ssas_dashboard_tab_title}>
                    <Container id="ssas_tab_cont-dashboard">
                        <Row>
                          <Col>
                            <h2>{ssasContent.ssas_dashboard_tab_table1_title}</h2>
                            {(() => {
                                let num_rows = 0;
                                if (activeAlerts != null) {
                                    num_rows = activeAlerts.length;
                                }
                                return (<CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_dashboard_tab_table1_title} columns={activeAlertsColumns} data={activeAlerts}
                                rows_displayed={num_rows} dropdown_placeholder={"All"} expand_element={null}/>);
                            })()}
                          </Col>
                          <Col>
                            <h2>{ssasContent.ssas_dashboard_tab_table2_title}</h2>
                            {(() => {
                                let num_rows = 0;
                                if (schedule != null) {
                                    num_rows = schedule.length;
                                }
                                return (<CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_dashboard_tab_table2_title} columns={scheduleColumns} data={schedule}
                                rows_displayed={num_rows} dropdown_placeholder={"All"} expand_element={null}/>);
                            })()}
                          </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="test_schedule" title={ssasContent.ssas_schedule_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_schedule_tab_title}</h2>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_schedule_tab_title} columns={testScheduleColumns} data={testSchedule}
                            rows_displayed={testSchedule ? testSchedule.length : 0} dropdown_placeholder={"All"} expand_element={expandElementTestSchedule}/>
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="contacts" title={ssasContent.ssas_contacts_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_contacts_tab_title}</h2>
                            <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ContactsTable"} columns={contactsColumns} data={contacts}
                            rows_displayed={contacts ? contacts.length : 0} dropdown_placeholder={"All"} expand_element={expandElementContact}/>
                            <Button variant="info" onClick={() => SF.addContactNotif(contacts)}>Add Contact</Button>
                            <Button variant="info" onClick={() => addExternalContact()}>Add External Contact</Button>
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="event_logs" title={ssasContent.ssas_event_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_event_tab_title}</h2>
                            {(() => {
                                let num_rows = 0;
                                if (events != null) {
                                    num_rows = events.length;
                                }
                                return (<CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|EventLogsTable"} columns={eventsColumns} data={events}
                                rows_displayed={num_rows} dropdown_placeholder={"All"} expand_element={null}/>);
                            })()}
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="vessels" title={ssasContent.ssas_vessel_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_vessel_tab_title}</h2>
                            <CustomTable breadcrumbs={`${breadcrumbs}|SSAS_Page|VesselsTable`} columns={ssasVesselsColumns} data={allSSASVessels}
                            rows_displayed={allSSASVessels ? allSSASVessels.length : 0} dropdown_placeholder={"All"} expand_element={null}/>
                          </div>
                        </Row>
                    </Container>
                </Tab>
            </Tabs>
        )}
      </Container>
    );
};

export default SSASPage;