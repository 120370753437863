import axios from "axios";
let access_token = null;
let refresh_token = null;

 /* LIVE! */
let api_gateway = 'https://api-gateway.maritrace.com/api';
//let api_gateway = 'http://localhost:8080/api';
export const post_data = async (endpoint, request_data) => {
  if(access_token == null){
    await startSystem();
  }
  const api_endpoint = api_gateway + "/microservice" + endpoint;
  try {
    const headers = {
      'Authorization': 'Bearer ' + access_token
    };

    const api_response = await axios.post(api_endpoint, request_data, { headers });
    let response = api_response.data;

    if (response.payload){
        response = response.payload;
    }
    if(response.Items){
        response = response.Items;
    }
    return response;
  } catch (error) {
    throw new Error(`API request failed: ${error.message}`);
  }
};

/* FOR LOCAL API TESTING
//let api_gateway = 'http://localhost:3001/api';
//export const post_data = async (endpoint, request_data) => {
  const api_endpoint = api_gateway + endpoint;
  try {
    const api_response = await axios.post(api_endpoint, request_data, {
      headers: {
        'Content-Type': 'application/json'  // Setting the correct Content-Type
      }
    });

    let response = api_response.data;
    if (response.data){
            response = response.data;
        }
    if (response.payload){
        response = response.payload;
    }
    if(response.Items){
        response = response.Items;
    }
    return response;
  } catch (error) {
    throw new Error(`API request failed: ${error.message}`);
  }
}; */


async function retrieveTokens() {
    try {
        //console.log(api_gateway + '/report-system');
        const requestBody = {
            "UserName": "maritrace-3-system",
            "UserId": "AIDBZ9G7QML53CQD3P5KL",
            "AccessKeyId": "AKIBZ9G7QML68EKDHH8W",
            "SecretAccessKey": "1Dtq3kLEQYScStx7lCfpYy7TjZ3zP9JrffGGOPLy"
        };
        const response = await axios.post(api_gateway + '/maritrace-3-system', requestBody);

        const payload = response.data.payload;
        access_token = payload['ACTIVE Token'];
        refresh_token = payload['REFRESH Token'];
        setTimeout(refreshAccessToken, 60 * 60 * 500);
    } catch (error) {
        console.error('Error retrieving tokens:', error);
        throw error;
    }
}
async function refreshAccessToken() {
  try {
    const response = await axios.get(api_gateway + '/active_token', {
         headers: {
             Authorization: `Bearer ${refresh_token}`
         }
     });

    const payload = response.data.payload;
    access_token = payload['active_token'];

    setTimeout(refreshAccessToken, 60 * 60 * 500);
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
}
function scheduleRefreshRefreshToken() {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  const timeUntilNextDay = tomorrow.getTime() - now.getTime();

  setTimeout(refreshRefreshToken, 60 * 60 * 1000 * 24);
}
async function refreshRefreshToken() {
  try {
    const response = await axios.get(api_gateway + '/refresh_token', {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });

    const payload = response.data.payload;
    refresh_token = payload['refresh_token'];

    // Schedule refresh of refresh token every day
    scheduleRefreshRefreshToken();
  } catch (error) {
    console.error('Error refreshing refresh token:', error);
    throw error;
  }
}
// Function to start the system
async function startSystem() {
  await retrieveTokens(); // Initial token acquisition
  scheduleRefreshRefreshToken(); // Schedule refresh of refresh token every day
}


var get_content = {
    "host": "maritrace",
    "language": "BR",
    "page": ""
};

export async function get_page_content(page_name){
    get_content.page = page_name;
    const res = await post_data("/get_localisation_content", get_content );

    const formatted_res: { [key: string]: string} = {};
    res.forEach((item: { id: string; content: string }) => {
      formatted_res[item.id] = item.content;
    });

    return formatted_res;
}