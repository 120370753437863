import CustomNotification from '../../Components/CustomNotification'
import { format } from 'date-fns';
import { createRoot } from 'react-dom/client';
import { Button, Form } from 'react-bootstrap';

function getRandomFourDigitNumber() {
    return String(Math.floor(Math.random() * 10000)).padStart(4, '0');
}
//accepts list of [type, uuid, name, , date]
export function handleSASSNotificationsList(params_list){
    let notificationTitle = [];
    let notificationContent = [];
    let notificationClass = [];
    let idList = [];
    let i = 0;
    params_list.forEach((notif) => {
        let thisTitle;
        let thisContent;
        let typeClass;
        idList.push("SASSAlertNot"+getRandomFourDigitNumber()+"-"+i);
        if(notif[0].toUpperCase() == "TEST") {
            thisTitle = "TEST MODE - SSAS FIRING FOR VESSEL: " + notif[2];
            thisContent = SASSTestModeAlert(notif[2], notif[3]);
            typeClass = "notification_SSAS_test";
        }
        else if(notif[0].toUpperCase() == "ALERT"){
            thisTitle = "SSAS FIRING FOR VESSEL: " + notif[2];
            thisContent = SASSActiveAlert(notif[2], notif[3]);
            typeClass = "notification_SSAS_alert";
        }

        notificationTitle.push(thisTitle);
        notificationContent.push(thisContent);
        notificationClass.push(typeClass);
    });

    let notificationContainer = document.createElement('div');
    notificationContainer.id = "SASSAlertNot";

    const root = createRoot(
        notificationContainer
    );

    root.render(
        <CustomNotification breadcrumbs="SASSNotifications" title={notificationTitle} text_content={notificationContent}
                custom_buttons={null} notification_class={notificationClass} call_back_func={null}
                on_close={SASSNotificationClose} />
    );
}

function SASSTestModeAlert(vessel, date){
    const formattedDate = format(new Date(date), "MMMM do yyyy, HH:mm 'UTC'");

    const addContent = (
        <p className="text">
            TEST MODE -- TEST MODE -- TEST MODE <br /><br />
            {formattedDate}<br /><br />
            CLICK <a href="#" onClick={(e) => { e.preventDefault(); SASSNotificationOnclick("test_item", "testing"); }}>
                HERE
            </a> TO OPEN LATEST SSAS MESSAGE, LOCATE VESSEL, AND ACKNOWLEDGE THIS MESSAGE.<br /><br />
            TEST MODE -- TEST MODE -- TEST MODE
        </p>
    );
    return addContent;
}
function SASSActiveAlert(vessel, date){
    const formattedDate = format(new Date(date), "MMMM do yyyy, HH:mm 'UTC'");

    const addContent = (
        <p className="text">
            {formattedDate}<br /><br />
            SSAS HAS BEEN ACTIVATED FOR VESSEL: SAMPLE VESSEL<br /><br />
            CLICK <a href="#" onClick={(e) => { e.preventDefault(); SASSNotificationOnclick("test_item", "testing"); }}>
                HERE
            </a> TO OPEN LATEST SSAS MESSAGE, LOCATE VESSEL, AND ACKNOWLEDGE THIS MESSAGE.
        </p>
    );
    return addContent;
}
function SASSNotificationClose (close_all: boolean, close_item:string){
    if(close_all){
        alert("close one");
    }
    //setIsPopupOpen(false);
}
function SASSNotificationCloseAll (close_all: boolean, close_item:string){
    if(close_all){
        alert("close all");
    }
    //setIsPopupOpen(false);
}
function SASSNotificationOnclick (item_id: string, more_info: string){
    alert("test: " + item_id);
}

export function handleSASSRecordDetails (type, alert_uuid, vessel_name){
    let thisTitle;
    let thisContent;
    let thisButton;
    let thisClass;
    if(type.toUpperCase() == "TEST"){
        thisTitle = "Record Test Details";
        thisClass = "record_SSAS_test";
        [thisContent, thisButton] = SASSRecordTest(alert_uuid, vessel_name);
    }if(type.toUpperCase() == "ALERT"){
        thisTitle = "Record Alert Details";
        thisClass = "record_SSAS_alert";
        [thisContent, thisButton] = SASSRecordAlert(alert_uuid, vessel_name);
    }

    let notificationContainer = document.createElement('div');
    notificationContainer.id = "SASSAlertNot";

    const root = createRoot(
        notificationContainer
    );

    root.render(
        <CustomNotification breadcrumbs="SASSNotifications" title={[thisTitle]} text_content={[thisContent]}
                custom_buttons={[thisButton]} notification_class={[thisClass]} call_back_func={null}
                on_close={SASSNotificationClose} />
    );
}
function SASSRecordAlert (alert_uuid, vessel_name){
    const record_content = (
        <>
            <p className="text">Please record any alert notes here</p>

            <Form.Control as="textarea" placeholder="Enter notes here"/>
        </>
    );
    const record_button = (
        <>
            <Button variant="success" onClick={(e) => handleDetailSave(e)}>Save</Button>
        </>
    );
    return [record_content, record_button];
}
function SASSRecordTest (alert_uuid, vessel_name){
    const record_content = (
        <>
            <p className="text">Please record any notes regarding the test here, then select one of the buttons below</p>

            <Form.Control as="textarea" placeholder="Enter notes here"/>
        </>
    );
    const record_button = (
        <>
            <Button variant="secondary">Not Applicable</Button>
            <Button variant="danger">Unsuccessful Test</Button>
            <Button variant="success">Successful Test</Button>
        </>
    );
    return [record_content, record_button];
}

function handleDetailSave(event){
    let currentElement = event.currentTarget.parentElement;
    let i = 0;
    while (currentElement && i < 5) {
        if (currentElement.classList.contains('popup-container')) {
           break; // Stop when the element with the desired class is found
        }
        currentElement = currentElement.parentElement; // Move to the next parent
        i++;
    }
    if (currentElement && currentElement.classList.contains('popup-container')) {
      // Find the textarea within the parent container
      const textarea = currentElement.querySelector('textarea');
      if (textarea) {
        const textareaValue = textarea.value;
        console.log('Saved textarea content:', textareaValue);
      }
    }
};

export function handleNotificationRender(containerId, breadcrumb, titleList, contentList, customButtonList, classList, onClose = null, callBack = null){
    let notificationContainer = document.createElement('div');
    notificationContainer.id = containerId;

    const root = createRoot(
        notificationContainer
    );

    root.render(
        <CustomNotification breadcrumbs={breadcrumb} title={titleList} text_content={contentList}
                custom_buttons={customButtonList} notification_class={classList} call_back_func={callBack}
                on_close={onClose} />
    );
};