import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/style.css';

interface Props {
  isOpen: boolean;
  modalContent: any;
}

const ModalHandler: React.FC<Props> = ({ isOpen, modalContent }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [modalOffset, setModalOffset] = useState({ x: 0, y: 0 });
  const [header, setHeader] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const modal_ref = useRef<HTMLDivElement | null>(null);


  const handleMouseDown = useCallback((e: Event) => {
    // Use type assertion to treat Event as MouseEvent
    const mouseEvent = e as MouseEvent;
    setIsDragging(true);
    setModalOffset({
      x: mouseEvent.clientX - position.x,
      y: mouseEvent.clientY - position.y,
    });
  }, [position]);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - modalOffset.x,
        y: e.clientY - modalOffset.y,
      });
    }
  }, [isDragging, modalOffset]);

  const handleWindowMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging]);

  useEffect(() => {
    if (isOpen) {
      const headerElement = modal_ref.current?.querySelector('.modal-header');
      if (headerElement) {
        headerElement.addEventListener('mousedown', handleMouseDown);

        // Cleanup function
        return () => {
          headerElement.removeEventListener('mousedown', handleMouseDown);
        };
      }
    }
  }, [isOpen, handleMouseDown]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mouseup', handleWindowMouseUp);
    } else {
      window.removeEventListener('mouseup', handleWindowMouseUp);
    }

    return () => {
      window.removeEventListener('mouseup', handleWindowMouseUp);
    };
  }, [isDragging, handleWindowMouseUp]);
  const modal_styles = {
    top: `${position.y}px`,
    left: `${position.x}px`,
    position: 'absolute' as 'absolute',
  };

  if (!isOpen) return null;

  return(
  <div className="modal-overlay" onMouseMove={handleMouseMove}>
      <div className="modal-body" style={modal_styles} ref={modal_ref}>
        {modalContent}
      </div>
  </div>
  );
};

export default ModalHandler;

    /*const HTMLStringToReactElement = (htmlString: string) => {
          const temp_div = document.createElement('div');
          temp_div.innerHTML = htmlString;

          // Extract content from specific elements
          const header_element = temp_div.querySelector('.modal-header')?.innerHTML || '';
          const content_element = temp_div.querySelector('.modal-content')?.innerHTML || '';

          return [header_element, content_element];
      };
      useEffect(() => {
        if(modalContent !== null){
            const html_string = ReactDOMServer.renderToStaticMarkup(modalContent)
            const react_elements = HTMLStringToReactElement(html_string);

            if(react_elements[0]){
                setHeader(react_elements[0]);
            }
            if(react_elements[1]){
                setContent(react_elements[1]);
            }
        }
      }, [modalContent]);*/
  /*return (
    <div className="modal-overlay" onMouseMove={handleMouseMove}>
      <div className="modal-body" style={modalStyles}>
        <div className="modal-header" onMouseDown={handleMouseDown}>
            <div dangerouslySetInnerHTML={{ __html: header}}/>
        </div>
        <div className="modal-content">
            <div dangerouslySetInnerHTML={{ __html: content}}/>
        </div>
      </div>
    </div>
  );*/