import React from 'react';
import ReactDOM from 'react-dom';
import Interactive_Map from '../Components/Interactive_Map';
import MainToolBar from '../Components/Main_ToolBar';
import { ModalProvider } from '../Components/Modal_Context';
import '../CSS/style.css';

interface Props {
}


const MainPage: React.FC<Props> = () => {
return (
    <ModalProvider>
        <div id="main_page">
            <Interactive_Map breadcrumbs="MainPage"/>
            <div id="main_tool_bar_cont">
                <MainToolBar breadcrumbs="MainPage"/>
            </div>
            <div id="interactive_map_container">
            </div>
        </div>
    </ModalProvider>
);
}

export default MainPage;