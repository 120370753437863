export function detectFormat(value) {
  if (value.includes('°') && value.includes("'") && value.includes('"')) {
    return 'DMS'; // Degrees, Minutes, Seconds
  } else if (value.includes('°') && value.includes("'")) {
    return 'DDM'; // Degrees and Decimal Minutes
  } else if (value.includes('°')) {
    return 'DD'; // Degrees and Decimal Minutes
  } else if (/^-?\d+(\.\d+)?$/.test(value)) {
    return 'D'; // Decimal Degrees
  }
  throw new Error('Unknown coordinate format');
}

export function convertCoordinates(value, isLatitude, formatTo = '', formatFrom = '') {
  function ddToDms(dd) {
    const degrees = Math.floor(dd);

    const minutesDecimal = (dd - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = Math.round((minutesDecimal - minutes) * 60);
    return { degrees, minutes, seconds };
  }
  function dmsToDd(degrees, minutes, seconds, direction) {
    const dir = Array.isArray(direction) ? direction[0] : direction;

    let decimalDegrees = Math.abs(degrees) + minutes / 60 + seconds / 3600;
    if (dir=== 'S' || dir === 'W') decimalDegrees = -decimalDegrees;

    return decimalDegrees;
  }
  function ddToDdm(dd) {
    let is_minus = false;
    const absoluteDd = Math.abs(dd);
    let degrees = Math.floor(absoluteDd);
    const minutes = (absoluteDd - degrees) * 60;

    if(dd < 0) is_minus = true;
    if (is_minus) degrees = -degrees;

    return { degrees, minutes: parseFloat(minutes.toFixed(2))};
  }
  function ddmToDd(degrees, minutes, direction) {
    const dir = Array.isArray(direction) ? direction[0] : direction;

    if (dir === 'S' || dir === 'W') {
        degrees = -Math.abs(degrees); // Ensure degrees are negative
    } else {
        degrees = Math.abs(degrees); // Ensure degrees are positive
    }

    const decimalDegrees = degrees + minutes / 60;
    return decimalDegrees
  }
  function formatDD(degrees, isLatitude) {
    const direction = degrees >= 0
        ? (isLatitude ? 'N' : 'E')
        : (isLatitude ? 'S' : 'W');
    const absDegrees = Math.abs(degrees);

    return `${direction} ${absDegrees}°`;
  }
  function formatDms(degrees, minutes, seconds, isLatitude) {
    const direction = degrees >= 0
        ? (isLatitude ? 'N' : 'E')
        : (isLatitude ? 'S' : 'W');
    const absDegrees = Math.abs(degrees);

    return `${direction} ${absDegrees}° ${minutes}' ${seconds}"`;
  }
  function formatDdm(degrees, minutes, isLatitude) {
      const direction = degrees >= 0
          ? (isLatitude ? 'N' : 'E')
          : (isLatitude ? 'S' : 'W');
      const absDegrees = Math.abs(degrees);
      const roundedMinutes = parseFloat(minutes.toFixed(2));

      return `${direction} ${absDegrees}° ${roundedMinutes}'`;
  }

  try{
      var formatted_D = false;
      let result;
      const direction = value.match(/[NSEW]/);

      if (!formatFrom) {
        formatFrom = detectFormat(value.toString());
      }

      if(formatFrom === "D"){
        if(formatTo === "DD" || formatTo === ""){
            formatted_D = true;
        }
        formatFrom = "DD";
      }
      else if(formatFrom === "DD"){
        value = value.match(/[\d.]+/g).map(Number);
        if( direction == "S" || direction == "W") value = -value;
      }
      else{
        value = value.replace(/^[NSEW\s]+/, '');
      }
      if(formatTo === "D"){
        formatTo = "DD";
      }

      if(formatted_D){
        result = formatDD(value, isLatitude);
      }
      else if (formatFrom === 'DD' && (formatTo === 'DDM' || formatTo === '')) {
        formatTo = 'DDM';
        result = ddToDdm(parseFloat(value));
      } else if (formatFrom === 'DD' && formatTo === 'DMS') {
        const { degrees: d, minutes: m, seconds: s } = ddToDms(parseFloat(value));
        result = formatDms(d, m, s, isLatitude);
      } else if (formatFrom === 'DDM' && formatTo === 'DD') {
        const [degrees, minutes] = value.split('°').map(Number);
        result = ddmToDd(degrees, minutes, direction);
      } else if (formatFrom === 'DDM' && (formatTo === 'DMS' || formatTo === '')) {
        const match = value.match(/(-?\d+(?:\.\d+)?)°\s*(\d+(?:\.\d+)?)'/);
        if (match) {
          formatTo = "DMS";
          const degrees = parseFloat(match[1]);
          const minutes = parseFloat(match[2]);
          const { degrees: d, minutes: m, seconds: s } = ddToDms(ddmToDd(degrees, minutes, direction));
          result = formatDms(d, m, s, isLatitude);
        } else {
          throw new Error('Invalid DDM format');
        }
      } else if (formatFrom === 'DMS' && (formatTo === 'DD' || formatTo === '')) {
        formatTo = 'DD';
        const [degrees, minutes, seconds] = value.split(/°|'|"/).map(Number);
        result = dmsToDd(degrees, minutes, seconds, direction);
      } else if (formatFrom === 'DMS' && formatTo === 'DDM') {
        const [degrees, minutes, seconds] = value.split(/°|'|"/).map(Number);
        const dd = dmsToDd(degrees, minutes, seconds, direction);
        result = ddToDdm(dd);
      } else if (!formatTo) {
        throw new Error('formatTo must be specified if formatFrom is given');
      } else {
        throw new Error('Invalid format combination');
      }

      if (formatTo === 'DDM') {
        return formatDdm(result.degrees, result.minutes, isLatitude);
      } else if (formatTo === 'DMS') {
        return result;
      } else if(formatted_D){
        return result;
      }else {
        return result.toFixed(2);
      }
  } catch (error) {
      return '0.00';
  }
}