import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format  } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../CSS/Module_CS/components.css';


interface Props {
  breadcrumbs: string;
  startDate: string;
  endDate: string;
  minimumDate: string;
  maximumDate: string;
  range: boolean;
  include_time: boolean;
  call_back_func: (returnDate: string, breadcrumbs:string) => void;
}

const CustomDatePicker: React.FC<Props> = ({ breadcrumbs, startDate, endDate, minimumDate, maximumDate, range, include_time, call_back_func }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>((startDate ? new Date(startDate) : null));
  const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>((endDate ? new Date(endDate) : null));
  const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null);
  const [createStartTime, setCreateStartTime] = useState<any | null>(null);
  const [createEndTime, setCreateEndTime] = useState<any | null>(null);
  const [datePickerProps, setDatePickerProps] = useState<any>({});
  const [isVisible, setVisible] = useState(true);
  const datePickerRef = useRef<HTMLDivElement>(null);
  let window_header: string = "Pick a date ";

  const handleRangeDateChange = (dates: [Date, Date]) => {
      const [start, end] = dates;
      setSelectedStartDate(start);
      setSelectedEndDate(end);
    };

  const handleSingleDateChange = (date: Date) => {
      setSelectedStartDate(date);
      if(selectedStartDate != null && !include_time){
          call_back_func(formatDateToString(selectedStartDate), breadcrumbs);
      }
    };

  const handleStartTimeChange = (date: Date) => {
     setSelectedStartTime(date);
  }
  const handleEndTimeChange = (date: Date) => {
      setSelectedEndTime(date);
  }
  const handleDateConfirmed = () => {
    if(selectedStartDate != null && !include_time && !range){
        call_back_func(formatDateToString(selectedStartDate), breadcrumbs);
    }
    else if(selectedStartDate != null && selectedEndDate != null && !include_time){
       call_back_func(formatDateToString(selectedStartDate) + "|" + formatDateToString(selectedEndDate), breadcrumbs);
    }
    else if(selectedStartDate != null && selectedStartTime != null && include_time && !range){
        const combined = combineDateAndTime(selectedStartDate, selectedStartTime);
        call_back_func(formatDateToString(combined), breadcrumbs);
    }
    else if(selectedStartDate != null && selectedStartTime != null && selectedEndDate != null && selectedEndTime != null && include_time){
        const combined = combineDateAndTime(selectedStartDate, selectedStartTime);
        const combined2 = combineDateAndTime(selectedEndDate, selectedEndTime);
        call_back_func(formatDateToString(combined) + "|" + formatDateToString(combined2), breadcrumbs);
    }
  }
  const handleCallback = (date: Date | null) => {
      if (date) {
        call_back_func(formatDateToString(date), breadcrumbs);
      }
    };


  const formatDateToString = (date: Date | null): string => {
      return date ? format(date, 'yyyy-MM-dd HH:mm:ss') : '';
    };
  const combineDateAndTime = (date: Date, time: Date) => {
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      const milliseconds = time.getMilliseconds();

      const combined = new Date(date);
      combined.setHours(hours, minutes, seconds, milliseconds);

      return combined;
    };
    const closeDatePicker = () => {
        if (datePickerRef.current) {
          const parentOverlay = datePickerRef.current.closest('.overlay');
          if (parentOverlay) {
            parentOverlay.remove();
          }
        }
        setVisible(false);
    };

    useEffect(() => {
        const dateProps: any = {
            inline: true,
            dateFormat: "yyyy-MM-dd HH:mm:ss",
            placeholderText: "Select date and time",
            selectsDisabledDaysInRange: true,
            maxDate: maximumDate,
            minDate: minimumDate,
        }
        if (include_time && !range) {
          /*dateProps.showTimeSelect = true;
          dateProps.timeFormat = 'HH:mm';
          dateProps.timeIntervals = 30;
          if(range) dateProps.timeCaption = 'Start Time';
          else dateProps.timeCaption = 'Time';*/
        }
        if (range) {
          dateProps.selectsRange = true;
          dateProps.selected = null;
          if (selectedStartDate) {dateProps.startDate = selectedStartDate;
          }
          if (selectedEndDate) {dateProps.endDate = selectedEndDate;
          }
          dateProps.onChange = handleRangeDateChange;
        }
        else{
          dateProps.onChange = handleSingleDateChange;
          if (selectedStartDate) dateProps.selected = selectedStartDate;
        }
        if (include_time) {
            let title = "Time";
            if(range) title = "Start Time";
            const startTime: any = {
              onChange: handleStartTimeChange,
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 30,
              timeCaption: title,
              inline: true,
            }
            if(selectedStartTime != null) startTime.selected = selectedStartTime;
            setCreateStartTime(startTime)
        }
        if (include_time && range) {
            const endTime: any = {
              onChange: handleEndTimeChange,
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 30,
              timeCaption: 'End Time',
              inline: true,
            }
            if(selectedEndTime != null) endTime.selected = selectedEndTime;
            setCreateEndTime(endTime)
        }
        if(include_time && range) window_header += "range and time...";
        else if(include_time && !range) window_header += " and time...";
        else if(!include_time && range) window_header += "range...";
        else window_header += "...";

        setDatePickerProps(dateProps);
    },[selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, range, include_time]);
  return (
  <>
  {isVisible && (
    <div className="date_picker_container" ref={datePickerRef}>
        <div className="date_picker_window_header">
            <div className="window_icon">
                <FontAwesomeIcon icon="calendar-days"/>
            </div>
            <p>{window_header}</p>
            <div className="window_close_icon">
                <FontAwesomeIcon icon={['fas', 'xmark']} onClick={closeDatePicker}/>
            </div>
        </div>
      <div className="date_picker_calendar">
        <DatePicker {...datePickerProps} />
      </div>
      {include_time && (
        <>
            <div className="date_picker_time start_time">
                <DatePicker {...createStartTime} />
            </div>
        {range && (
            <>
                <div className="date_picker_time end_time">
                    <DatePicker {...createEndTime} />
                </div>
            </>
        )}
        </>
      )}
      <button className="date_picker_submit" onClick={handleDateConfirmed}>Okay</button>
      <button style={{ float: 'right', clear: 'none' }} className="date_picker_submit" onClick={closeDatePicker}>Close</button>
    </div>
    )}
    </>
  );
};

export default CustomDatePicker;