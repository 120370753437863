import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';

interface DropdownOption {
  value: string;
  label: string;
}

interface Props {
  breadcrumbs: string;
  iconClass: string;
  options: DropdownOption[];
  call_back_func: (parentPage: string, element: string, breadcrumbs:string) => void;
}

const IconDropdown: React.FC<Props> = ({ breadcrumbs, iconClass, options, call_back_func }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const selectRef = useRef<HTMLDivElement>(null); // Change to useRef<HTMLDivElement> for correct type
  const dropdownRef = useRef<HTMLUListElement>(null);
  const [parentPage, setParentPage] = useState<string>("");
  const [styleInfiniteLoop, setStyleInfiniteLoop] = useState<number>(0);
  const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({
      display: 'none',
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      zIndex: 1000,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    });
  const iconProp: IconProp = iconClass as IconProp;

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  useEffect(() => {
    if(showOptions){
        setDropdownStyle((prevStyle) => ({ ...prevStyle, display: 'block' }));
        setStyleInfiniteLoop(0);
    } else{
        setDropdownStyle((prevStyle) => ({ ...prevStyle, display: 'none' }));
    }
  }, [showOptions]);

  const handleOptionSelect = (parentPage: string, event: string) => {
    setSelectedOption(event);
    setShowOptions(false);
    call_back_func(parentPage, event, breadcrumbs)
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    const fetchNewStyle = async () => {
        try {
          if (showOptions && dropdownRef.current) {
            const newStyle = { ...dropdownStyle};
            const dropdown = dropdownRef.current;
            const { innerWidth, innerHeight } = window;
            const rect = dropdown.getBoundingClientRect();

            if (rect.right > innerWidth) {
                newStyle.left = 'auto';
                newStyle.right = '0';
            } else if (rect.left < 0) {
                newStyle.left = '0';
                newStyle.right = 'auto';
            }
            if (rect.bottom > innerHeight) {
                newStyle.top = 'auto';
                newStyle.bottom = '100%';
            } else if (rect.top < 0) {
                newStyle.top = '100%';
                newStyle.bottom = 'auto';
            }
            if(newStyle !== dropdownStyle){
                setDropdownStyle(newStyle);
            }
          } /*else {
            setDropdownStyle((prevStyle) => ({ ...prevStyle, display: 'none' }));
          }*/
          setParentPage(breadcrumbs.split("|")[0] + iconClass);
        } catch (error) {
          console.error(error);
        }
    };
    if(dropdownStyle.display === 'block'){
        fetchNewStyle();
    }
    if(styleInfiniteLoop < 10){
        setStyleInfiniteLoop(styleInfiniteLoop+1);
    }
    }, [styleInfiniteLoop]); //<- causes infinite loop>
    //}, [showOptions, dropdownStyle]); //<- causes infinite loop>

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div id={parentPage} className="dropdown-container" ref={selectRef}>
      <button onClick={toggleOptions} className="btn">
        <FontAwesomeIcon icon={iconProp} /><span className="options_arrow"></span>
      </button>
        <ul className="dropdown-menu" ref={dropdownRef} style={dropdownStyle}>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionSelect(parentPage, option.value)}
              className={`dropdown-item ${selectedOption === option.value ? 'selected' : ''}`} >
              {option.label}
            </li>
          ))}
        </ul>
      <style>
        {`
          .dropdown-container {
            position: relative;
            display: inline-block;
          }

          .dropdown-item {
            padding: 8px 12px;
            cursor: pointer;
          }

          .dropdown-item.selected {
            background-color: #f0f0f0;
          }
        `}
      </style>

    </div>
  );
};

export default IconDropdown;
