import { v4 as uuidv4 } from 'uuid';

// Retrieve the session ID from localStorage or generate a new one
const session_id = localStorage.getItem('sessionID') || generateGUID();
export let session_user_uuid = "";
export let session_parent_uuid = "";

export function getSessionUserUuid() {
  return session_user_uuid;
}

export function setSessionUserUuid(uuid) {
  session_user_uuid = uuid;
}

export function getSessionParentUuid() {
  return session_parent_uuid;
}

export function setSessionParentUuid(uuid) {
  session_parent_uuid = uuid;
}

function generateGUID() {
  return uuidv4();
}

// Store the session ID in localStorage if it doesn't exist
if (!localStorage.getItem('sessionID')) {
  localStorage.setItem('sessionID', session_id);
}

export { session_id };