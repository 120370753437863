import { session_user_uuid, session_parent_uuid } from '../session.js';
import { post_data, get_page_content } from '../API_Calls.js';

export interface MaritraceUser {
    user_account_uuid: string;
    maritrace_parent_account: MaritraceParent;
    email: string;
    first_name: string;
    last_name: string;
    expires: DateTime;
}
export interface MaritraceParent {
    parent_account_uuid: string;
    parent_account_name: string;
    tracking_slots: int;
}
export async function getMaritraceUsers(userUuid, parentUuid, byParentUuid = false, byUserUuid = false){
    let parent_param = null;
    let user_param = null;

    if(parentUuid != "") parent_param = parentUuid;
    else if(byParentUuid) parent_param = session_parent_uuid;

    if(userUuid != "") user_param = userUuid;
    else if(byUserUuid) user_param = session_user_uuid;

    const maritrace_user_parameters = {
          "parent_account_uuids" :  [...parent_param ? [parent_param] : [],],
          "user_account_uuids" : [...user_param ? [user_param] : [],],
      }
    console.log("maritrace_user_parameters: ", maritrace_user_parameters);
    const res_user = await post_data("/get_MariTraceUserAccount", maritrace_user_parameters);

    if(res_user.length <= 0){
        console.log("MariTrace user not found");
        return null;
    }
    else{
        let result: MaritraceUser[] = res_user;
        return result;
    }
}
