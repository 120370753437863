import React, { createContext, useContext, useEffect, useState, ReactNode, FC } from 'react';
import ReactDOM from 'react-dom';
import ModalHandler from './Modal_Handler';

interface ModalContextType {
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState<React.ReactNode>(null);

    const showModal = (content: React.ReactNode) => {
      setModalContent(content);
      setIsOpen(true);
    };

    const hideModal = () => {
      setIsOpen(false);
      setModalContent(null);
    };
    return (
      <ModalContext.Provider value={{ showModal, hideModal }}>
        {children}
        {isOpen && ReactDOM.createPortal(
          <ModalHandler isOpen={isOpen} modalContent={modalContent} />,
          document.body
        )}
      </ModalContext.Provider>
    );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};