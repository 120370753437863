export type LoginContent = {
  login_title: string;
  login_welcome_text: string;
  login_email: string;
  login_password: string;
  login_log_in: string;
  login_expired: string;
  login_forgotten: string;
  login_header_text: string;
  login_fail_email: string;
  login_fail_password: string;
}

export const default_login_content: LoginContent = {
  login_title: "",
  login_welcome_text: "",
  login_email: "",
  login_password: "",
  login_log_in: "",
  login_expired: "",
  login_forgotten: "",
  login_header_text: "",
  login_fail_email: "",
  login_fail_password: "",
};